import "../styles/word-game.css";
import { useState, useMemo, useEffect } from "react";
import Header from "./components/header";
import Rules from "./components/rules";
import Timer from "./components/timer";
import Score from "./components/score";
import Word from "./components/word";
import Keyboard from "./components/keyboard";
import Results from "./components/results";
import {
  persistGame,
  gameStats
} from "./repositories/storage";
import Game from "./game/Game";

type WordGameProps = {
  initialWord: string;
  timer: number;
}


function WordGame({initialWord, timer}: WordGameProps) {
  const stats = gameStats();

  const [showRules, setShowRules] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameFinished, setGameFinished] = useState(stats.playedToday ?? false);
  const [score, setScore] = useState<number>(stats.todaysScore ?? -1);
  const [totalScore, setTotalScore] = useState<number>(stats.totalScore ?? 0);
  const [word, setWord] = useState<string>(initialWord);
  const [selectedLetterIndex, setSelectedLetterIndex] = useState<number>(-1);
  const [previousGuesses, setPreviousGuesses] = useState<string[]>([]);
  const [warning, setWarning] = useState('');

  useMemo(function() {
    if (!gameFinished) {
      setPreviousGuesses(prev => {
        prev.push(word);
        return prev;
      });
  
      setScore(prev => prev + 1);
    }
  }, [word, gameFinished]);

  useEffect(() => {
    if (isPlaying) {
      setShowRules(false);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (gameFinished && !stats.playedToday) {
      setTotalScore(prev => {
        const updatedTotalScore = prev + score;
        persistGame({todaysScore: score, totalScore: updatedTotalScore ?? 0 + score, playedToday: true, lastPlayed: Game.id()});
        return updatedTotalScore;
      });
    }
  }, [gameFinished, score, stats.playedToday]);

  return (
    <div className="WordGame">
      {showRules && !gameFinished && !isPlaying && 
        <Rules
          onClose={() => setIsPlaying(true)}
          startGame={() => setIsPlaying(true)}
        />
      }

      <Header onHeaderClick={() => setShowRules(true)} />
      <div>
        <div className="game-metrics">
          <Timer startTimer={isPlaying} onEnd={() => setGameFinished(true)} seconds={timer} />
          <Score score={score} />
        </div>

        <Word
          currentWord={word}
          selectedLetter={selectedLetterIndex}
          disabled={gameFinished}
          setSelectedLetter={setSelectedLetterIndex}
        />

        <div className="warning">{warning}</div>
      </div>
      <Keyboard
        previousGuesses={previousGuesses}
        word={word}
        setWord={setWord}
        selectedLetterIndex={selectedLetterIndex}
        isPlaying={isPlaying}
        wordCheck={setWarning}
      />

      {gameFinished && <Results score={score} totalScore={totalScore} />}
    </div>
  );
}

export default WordGame;
