import '../../../styles/components/keyboard.css';
import Key from './Key';
import { keys } from '../../keyboard/keys';
import { isWord } from '../../repositories/dictionary';

type KeyboardProps = {
  isPlaying: boolean;
  previousGuesses: string[];
  word: string;
  setWord: (word: string) => void;
  selectedLetterIndex: number;
  wordCheck: (warning: string) => void;
}

function Keyboard({isPlaying, previousGuesses, word, setWord, selectedLetterIndex, wordCheck}: KeyboardProps) {
  const handleKeyClick = (letter: string) => {
    let newWord = word.split('');
    newWord[selectedLetterIndex] = letter;
    const newWordString = newWord.join('');

    if (!isWord(newWordString)) {
      wordCheck(`unrecognised word "${newWordString}"`);
    } else if (previousGuesses.includes(newWordString)) {
      wordCheck(`word already used "${newWordString}"`);
    } else {
      setWord(newWordString);
      wordCheck('');
    }
  }

  return (
    <div className="Keyboard">
      {keys.map((row, rowIndex) => (
        <div key={`keyboard-row-${rowIndex}`} className="keyboard-row">
          {row.map((key, keyIndex) => (
            <Key key={`key-${keyIndex}`} letter={key} onClick={handleKeyClick} isPlaying={isPlaying} />
          ))}
        </div>
      ))}
    </div>
  );
}

export default Keyboard;
