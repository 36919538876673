type LetterProps = {
  value: string;
  highlight: boolean;
  letterIndex: number;
  disabled: boolean;
  handleClick?: (key: number) => void;
}

function Letter({value, highlight, handleClick, disabled, letterIndex}: LetterProps) {
  const classes = [
    'Letter',
    ...(highlight ? ['highlight'] : []),
    ...(disabled ? ['disabled'] : [])
  ].join(' ');

  return (
    <div className={classes} onClick={() => !disabled && handleClick?.(letterIndex)}>
      <span className='letter-value'>{value}</span>
    </div>
  );
}

Letter.defaultProps = {
  highlight: false
}

export default Letter;
