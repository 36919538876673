type KeyProps = {
  isPlaying: boolean;
  letter: string;
  onClick: (letter: string) => void;
}

function Key({isPlaying, letter, onClick}: KeyProps) {
  return (
    <div className="Key" onClick={() => isPlaying && onClick(letter)}>
      <span>{letter}</span>
    </div>
  );
}

export default Key;
