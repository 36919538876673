import './styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import WordGame from './js/WordGame';
import Game from './js/game/Game';
import { gameStats, persistGame, userPreferences } from './js/repositories/storage';

if (userPreferences().darkMode) {
  document.getElementsByTagName('body')[0].classList.add('dark')
}

if (gameStats().lastPlayed !== Game.id()) {
  persistGame({playedToday: false, todaysScore: -1});
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <WordGame initialWord={Game.initialWord()} timer={Game.countdown()} />
  </React.StrictMode>
);
