import { getDailyWord } from "./initialWords";

let _originalStartDate = new Date(2023, 0, 18);
let _isPlaying = false;
let _countdown = 60;

function getDayOffset() {
  const currentDate = new Date();

  const diffInMs = Math.abs(currentDate.valueOf() - _originalStartDate.valueOf());
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
}

const Game = {
  name: 'Fourword',
  url: 'https://fourword.uk',
  email: 'four_word@outlook.com',
  isPlaying: () => _isPlaying,
  setIsPlaying: () => { _isPlaying = true },
  initialWord: () => getDailyWord(_originalStartDate),
  countdown: () => _countdown,
  id: () => getDayOffset()
}

export default Game;
