enum Metric {
  USER = 'user',
  GAME = 'game'
}

type Stats = {
  todaysScore?: number;
  playedToday?: boolean;
  totalScore?: number;

  /**
   * @description - game ID last played (offset in days since original start date)
   */
  lastPlayed?: number;
}

type Preferences = {
  darkMode?: boolean;
}

export function gameStats(): Stats {
  const stats = localStorage.getItem(Metric.GAME);
  return stats ? JSON.parse(stats) : {};
}

export function persistGame(settings: Stats): void {
  const prev = localStorage.getItem(Metric.GAME);

  if (prev) {
    const update = {...JSON.parse(prev), ...settings};
    localStorage.setItem(Metric.GAME, JSON.stringify(update));
    return;
  }
  localStorage.setItem(Metric.GAME, JSON.stringify(settings));
}

export function userPreferences(): Preferences {
  const preferences = localStorage.getItem(Metric.USER);
  return preferences ? JSON.parse(preferences) : {};
}

export function persistPreferences(settings: Preferences): void {
  const prev = localStorage.getItem(Metric.USER);

  if (prev) {
    const update = {...JSON.parse(prev), ...settings};
    localStorage.setItem(Metric.USER, JSON.stringify(update));
    return;
  }
  localStorage.setItem(Metric.USER, JSON.stringify(settings));
}
