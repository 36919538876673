import "../../../../styles/components/button.css";

type ButtonProps = {
  handleClick: () => void;
  children: React.ReactNode;
  dataTestId?: string;
}

function Button({children, handleClick, dataTestId}: ButtonProps) {
  return (
    <button data-testid={dataTestId ?? "button"} className="Button" onClick={handleClick}>
      {children}
    </button>
  );
}

export default Button;
