import "../../../styles/components/header.css";
import { useState, useEffect } from "react";
import { MdOutlineWbSunny, MdDarkMode } from "react-icons/md";
import { userPreferences, persistPreferences } from "../../repositories/storage";
import logo from "../../../assets/logo.png";

type HeaderProps = {
  onHeaderClick: () => void;
}

function Header({onHeaderClick}: HeaderProps) {
  const preferences = userPreferences();
  const [darkMode, setDarkMode] = useState(preferences.darkMode ?? false);

  useEffect(() => {
    persistPreferences({darkMode});

    if (darkMode) {
      document.getElementsByTagName('body')[0].classList.add('dark')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('dark')
    }
  }, [darkMode])

  return (
    <div data-testid="header" className="Header" onClick={onHeaderClick}>
      <div className="title-container">
        <img className="logo" src={logo} alt="fourword-logo" />
      </div>

      <div>
        <span data-testid="theme-toggle" className="theme" onClick={() => setDarkMode(prev => !prev)}>
          {darkMode ? <MdOutlineWbSunny /> : <MdDarkMode />}
        </span>
      </div>
    </div>
  );
}

export default Header;
