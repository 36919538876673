import { useState, useMemo } from "react";
import "../../../styles/components/results.css";
import { RxCalendar, RxClock } from "react-icons/rx";
import { SlTrophy } from "react-icons/sl";
import Modal from "../common/modal";
import Button from "../common/button";
import Game from "../../game/Game";

type ResultsProps = {
  score: number;
  totalScore: number;
}

function emoji(score = 0) {
  if (score < 10) {
    return '💩'
  } else if (score >= 10 && score < 15) {
    return '🥉';
  } else if (score >= 15 && score < 20) {
    return '🥈';
  } else {
    return '🥇'
  }
}

function textToShare(score: number) {
  return `📖 ${Game.name} ${Game.id() + 1}\n${emoji(score)} ${score}\n\n${Game.url}`;
}

function secondsUntilMidnight() {
  var midnight = new Date();
  midnight.setHours(24, 0, 0, 0);
  return Math.floor((midnight.getTime() - new Date().getTime()) / 1000);
}

function Results({score, totalScore}: ResultsProps) {
  const [open, setOpen] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState<number>(secondsUntilMidnight());

  useMemo(function() {
    setInterval(function() {
      setTimeRemaining(prev => prev - 1);
    }, 1000);
  }, []);

  function formatTimeRemaining() {
    const hours = Math.floor(timeRemaining / 3600).toString().padStart(2, '0');
    const minutes = Math.floor(timeRemaining % 3600 / 60).toString().padStart(2, '0');
    const seconds = Math.floor(timeRemaining % 60).toString().padStart(2, '0');

    return [hours, minutes, seconds];
  }

  const [hours, minutes, seconds] = formatTimeRemaining();

  return (
    <>
      {open && 
        <Modal onClose={() => setOpen(false)}>
          <div className="section">
            <div className="modal-title">Statistics</div>
            <div className="score-row">
              <div className="metric">
                <span className="icon"><RxCalendar /></span>
                <span className="metric-score">{score}</span>
                <span className="metric-title">Today</span>
              </div>
    
              <div className="metric">
                <span className="icon"><SlTrophy /></span>
                <span className="metric-score">{totalScore}</span>
                <span className="metric-title">Total</span>
              </div>
            </div>
    
            <div className="share-row">
              <Button handleClick={() => global.navigator.share({text: textToShare(score)})}>Share</Button>
            </div>
          </div>
    
          <div className="metric">
            <span className="icon"><RxClock /></span>
            <span>Next {Game.name} in {`${hours}h ${minutes}m ${seconds}s`}</span>
          </div>
        </Modal>
      }
    </>
  );
}

export default Results;
