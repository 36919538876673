import '../../../styles/components/word.css';
import Letter from './Letter';

type WordProps = {
  currentWord: string;
  selectedLetter: number;
  disabled: boolean;
  setSelectedLetter: (selectedLetterIndex: number) => void;
}

function Word({currentWord, selectedLetter, disabled, setSelectedLetter}: WordProps) {
  return (
    <div className="Word">
      {Array(4).fill(0).map((_letter, index) => (
        <Letter
          value={currentWord[index]}
          key={index}
          highlight={selectedLetter === index}
          letterIndex={index}
          handleClick={setSelectedLetter}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

export default Word;
