const initialWords: string[] = [
  'FAST',
  'PILL',
  'DASH',
  'MOCK',
  'NAME',
  'BRAP',
  'STUD',
  'HUTS',
  'FROM',
  'GRAM',
  'PUSH',
  'LAST',
  'AIMS',
  'CHUM',
  'TRIP',
  'FLAP',
  'BLIP',
  'MONK',
  'RITE',
  'SPOT',
  'LONE',
  "SKIP",
  "PURE",
  "HOLD",
  "LEFT",
  "TAKE",
  "FROG",
  "GLUM",
  "SAGA",
  "CANE",
  "EARN",
  "BARS",
  "FACT",
  "MAUL",
  "NAIL",
  "YEAR",
  "DAMP"
];

export function getDailyWord(originalStartDate: Date): string {
  const currentDate = new Date();

  const diffInMs = Math.abs(currentDate.valueOf() - originalStartDate.valueOf());
  const diffDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  return initialWords[diffDays % initialWords.length];
}
