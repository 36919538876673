const words: Record<string, true> = {
  AAHS: true,
  AALS: true,
  ABAC: true,
  ABAS: true,
  ABBA: true,
  ABBE: true,
  ABBS: true,
  ABED: true,
  ABER: true,
  ABET: true,
  ABID: true,
  ABLE: true,
  ABLY: true,
  ABOS: true,
  ABRI: true,
  ABUT: true,
  ABYE: true,
  ABYS: true,
  ACAI: true,
  ACCA: true,
  ACED: true,
  ACER: true,
  ACES: true,
  ACHE: true,
  ACHY: true,
  ACID: true,
  ACME: true,
  ACNE: true,
  ACRE: true,
  ACRO: true,
  ACTA: true,
  ACTS: true,
  ACYL: true,
  ADAW: true,
  ADDS: true,
  ADDY: true,
  ADIT: true,
  ADOS: true,
  ADRY: true,
  ADZE: true,
  AEON: true,
  AERO: true,
  AERY: true,
  AESC: true,
  AFAR: true,
  AFFY: true,
  AFRO: true,
  AGAR: true,
  AGAS: true,
  AGED: true,
  AGEE: true,
  AGEN: true,
  AGER: true,
  AGES: true,
  AGHA: true,
  AGIN: true,
  AGIO: true,
  AGLU: true,
  AGLY: true,
  AGMA: true,
  AGOG: true,
  AGON: true,
  AGRO: true,
  AGUE: true,
  AHED: true,
  AHEM: true,
  AHIS: true,
  AHOY: true,
  AIAS: true,
  AIDA: true,
  AIDE: true,
  AIDS: true,
  AIGA: true,
  AILS: true,
  AIMS: true,
  AINE: true,
  AINS: true,
  AIRN: true,
  AIRS: true,
  AIRT: true,
  AIRY: true,
  AITS: true,
  AITU: true,
  AJAR: true,
  AJEE: true,
  AJIS: true,
  AKAS: true,
  AKED: true,
  AKEE: true,
  AKES: true,
  AKIN: true,
  ALAE: true,
  ALAN: true,
  ALAP: true,
  ALAR: true,
  ALAS: true,
  ALAY: true,
  ALBA: true,
  ALBE: true,
  ALBS: true,
  ALCO: true,
  ALEC: true,
  ALEE: true,
  ALEF: true,
  ALES: true,
  ALEW: true,
  ALFA: true,
  ALFS: true,
  ALGA: true,
  ALIF: true,
  ALIT: true,
  ALKO: true,
  ALKY: true,
  ALLS: true,
  ALLY: true,
  ALMA: true,
  ALME: true,
  ALMS: true,
  ALOD: true,
  ALOE: true,
  ALOO: true,
  ALOW: true,
  ALPS: true,
  ALSO: true,
  ALTO: true,
  ALTS: true,
  ALUM: true,
  ALUS: true,
  AMAH: true,
  AMAS: true,
  AMBO: true,
  AMEN: true,
  AMES: true,
  AMIA: true,
  AMID: true,
  AMIE: true,
  AMIN: true,
  AMIR: true,
  AMIS: true,
  AMLA: true,
  AMMO: true,
  AMOK: true,
  AMPS: true,
  AMUS: true,
  AMYL: true,
  ANAL: true,
  ANAN: true,
  ANAS: true,
  ANCE: true,
  ANDS: true,
  ANES: true,
  ANEW: true,
  ANGA: true,
  ANIL: true,
  ANIS: true,
  ANKH: true,
  ANNA: true,
  ANNO: true,
  ANNS: true,
  ANOA: true,
  ANON: true,
  ANOW: true,
  ANSA: true,
  ANTA: true,
  ANTE: true,
  ANTI: true,
  ANTS: true,
  ANUS: true,
  APAY: true,
  APED: true,
  APER: true,
  APES: true,
  APEX: true,
  APOD: true,
  APOS: true,
  APPS: true,
  APSE: true,
  APSO: true,
  APTS: true,
  AQUA: true,
  ARAK: true,
  ARAR: true,
  ARBA: true,
  ARBS: true,
  ARCH: true,
  ARCO: true,
  ARCS: true,
  ARDS: true,
  AREA: true,
  ARED: true,
  AREG: true,
  ARES: true,
  ARET: true,
  AREW: true,
  ARFS: true,
  ARGH: true,
  ARIA: true,
  ARID: true,
  ARIL: true,
  ARIS: true,
  ARKS: true,
  ARLE: true,
  ARMS: true,
  ARMY: true,
  ARNA: true,
  AROW: true,
  ARPA: true,
  ARSE: true,
  ARSY: true,
  ARTI: true,
  ARTS: true,
  ARTY: true,
  ARUM: true,
  ARVO: true,
  ARYL: true,
  ASAR: true,
  ASCI: true,
  ASEA: true,
  ASHY: true,
  ASKS: true,
  ASPS: true,
  ATAP: true,
  ATES: true,
  ATMA: true,
  ATOC: true,
  ATOK: true,
  ATOM: true,
  ATOP: true,
  ATUA: true,
  AUAS: true,
  AUFS: true,
  AUGH: true,
  AUKS: true,
  AULA: true,
  AULD: true,
  AUNE: true,
  AUNT: true,
  AURA: true,
  AUTO: true,
  AVAL: true,
  AVAS: true,
  AVEL: true,
  AVER: true,
  AVES: true,
  AVID: true,
  AVOS: true,
  AVOW: true,
  AWAY: true,
  AWDL: true,
  AWED: true,
  AWEE: true,
  AWES: true,
  AWFY: true,
  AWKS: true,
  AWLS: true,
  AWNS: true,
  AWNY: true,
  AWOL: true,
  AWRY: true,
  AXAL: true,
  AXED: true,
  AXEL: true,
  AXES: true,
  AXIL: true,
  AXIS: true,
  AXLE: true,
  AXON: true,
  AYAH: true,
  AYES: true,
  AYIN: true,
  AYRE: true,
  AYUS: true,
  AZAN: true,
  AZON: true,
  AZYM: true,
  BAAL: true,
  BAAS: true,
  BABA: true,
  BABE: true,
  BABU: true,
  BABY: true,
  BACH: true,
  BACK: true,
  BACS: true,
  BADE: true,
  BADS: true,
  BAEL: true,
  BAFF: true,
  BAFT: true,
  BAGH: true,
  BAGS: true,
  BAHT: true,
  BAHU: true,
  BAIL: true,
  BAIT: true,
  BAJU: true,
  BAKE: true,
  BALD: true,
  BALE: true,
  BALK: true,
  BALL: true,
  BALM: true,
  BALS: true,
  BALU: true,
  BAMS: true,
  BANC: true,
  BAND: true,
  BANE: true,
  BANG: true,
  BANI: true,
  BANK: true,
  BANS: true,
  BANT: true,
  BAPS: true,
  BAPU: true,
  BARB: true,
  BARD: true,
  BARE: true,
  BARF: true,
  BARK: true,
  BARM: true,
  BARN: true,
  BARP: true,
  BARS: true,
  BASE: true,
  BASH: true,
  BASK: true,
  BASS: true,
  BAST: true,
  BATE: true,
  BATH: true,
  BATS: true,
  BATT: true,
  BAUD: true,
  BAUK: true,
  BAUR: true,
  BAWD: true,
  BAWK: true,
  BAWL: true,
  BAWN: true,
  BAWR: true,
  BAYE: true,
  BAYS: true,
  BAYT: true,
  BAZZ: true,
  BEAD: true,
  BEAK: true,
  BEAL: true,
  BEAM: true,
  BEAN: true,
  BEAR: true,
  BEAT: true,
  BEAU: true,
  BECK: true,
  BEDE: true,
  BEDS: true,
  BEDU: true,
  BEEF: true,
  BEEN: true,
  BEEP: true,
  BEER: true,
  BEES: true,
  BEET: true,
  BEGO: true,
  BEGS: true,
  BEIN: true,
  BELL: true,
  BELS: true,
  BELT: true,
  BEMA: true,
  BEND: true,
  BENE: true,
  BENI: true,
  BENJ: true,
  BENS: true,
  BENT: true,
  BERE: true,
  BERG: true,
  BERK: true,
  BERM: true,
  BEST: true,
  BETA: true,
  BETE: true,
  BETH: true,
  BETS: true,
  BEVY: true,
  BEYS: true,
  BHAI: true,
  BHAT: true,
  BHEL: true,
  BHUT: true,
  BIAS: true,
  BIBB: true,
  BIBE: true,
  BIBS: true,
  BICE: true,
  BIDE: true,
  BIDI: true,
  BIDS: true,
  BIEN: true,
  BIER: true,
  BIFF: true,
  BIGA: true,
  BIGG: true,
  BIGS: true,
  BIKE: true,
  BILE: true,
  BILK: true,
  BILL: true,
  BIMA: true,
  BIND: true,
  BINE: true,
  BING: true,
  BINK: true,
  BINS: true,
  BINT: true,
  BIOG: true,
  BIOS: true,
  BIRD: true,
  BIRK: true,
  BIRL: true,
  BIRO: true,
  BIRR: true,
  BISE: true,
  BISH: true,
  BISK: true,
  BIST: true,
  BITE: true,
  BITO: true,
  BITS: true,
  BITT: true,
  BIZE: true,
  BLAB: true,
  BLAD: true,
  BLAE: true,
  BLAG: true,
  BLAH: true,
  BLAM: true,
  BLAT: true,
  BLAW: true,
  BLAY: true,
  BLEB: true,
  BLED: true,
  BLEE: true,
  BLET: true,
  BLEW: true,
  BLEY: true,
  BLIN: true,
  BLIP: true,
  BLIT: true,
  BLOB: true,
  BLOC: true,
  BLOG: true,
  BLOT: true,
  BLOW: true,
  BLUB: true,
  BLUE: true,
  BLUR: true,
  BOAB: true,
  BOAK: true,
  BOAR: true,
  BOAS: true,
  BOAT: true,
  BOBA: true,
  BOBO: true,
  BOBS: true,
  BOCK: true,
  BODE: true,
  BODS: true,
  BODY: true,
  BOEP: true,
  BOET: true,
  BOFF: true,
  BOGS: true,
  BOGY: true,
  BOHO: true,
  BOHS: true,
  BOIL: true,
  BOIS: true,
  BOKE: true,
  BOKO: true,
  BOKS: true,
  BOLA: true,
  BOLD: true,
  BOLE: true,
  BOLL: true,
  BOLO: true,
  BOLT: true,
  BOMA: true,
  BOMB: true,
  BONA: true,
  BOND: true,
  BONE: true,
  BONG: true,
  BONK: true,
  BONY: true,
  BOOB: true,
  BOOH: true,
  BOOK: true,
  BOOL: true,
  BOOM: true,
  BOON: true,
  BOOR: true,
  BOOS: true,
  BOOT: true,
  BOPS: true,
  BORA: true,
  BORD: true,
  BORE: true,
  BORK: true,
  BORM: true,
  BORN: true,
  BORS: true,
  BORT: true,
  BOSH: true,
  BOSK: true,
  BOSS: true,
  BOTA: true,
  BOTE: true,
  BOTH: true,
  BOTS: true,
  BOTT: true,
  BOUK: true,
  BOUN: true,
  BOUT: true,
  BOWL: true,
  BOWR: true,
  BOWS: true,
  BOXY: true,
  BOYF: true,
  BOYG: true,
  BOYO: true,
  BOYS: true,
  BOZO: true,
  BRAD: true,
  BRAE: true,
  BRAG: true,
  BRAK: true,
  BRAN: true,
  BRAP: true,
  BRAS: true,
  BRAT: true,
  BRAW: true,
  BRAY: true,
  BRED: true,
  BREE: true,
  BREI: true,
  BREN: true,
  BRER: true,
  BREW: true,
  BREY: true,
  BRIE: true,
  BRIG: true,
  BRIK: true,
  BRIM: true,
  BRIN: true,
  BRIO: true,
  BRIS: true,
  BRIT: true,
  BROD: true,
  BROG: true,
  BROO: true,
  BROS: true,
  BROW: true,
  BRRR: true,
  BRUS: true,
  BRUT: true,
  BRUX: true,
  BUAT: true,
  BUBA: true,
  BUBO: true,
  BUBS: true,
  BUBU: true,
  BUCK: true,
  BUDA: true,
  BUDI: true,
  BUDO: true,
  BUDS: true,
  BUFF: true,
  BUFO: true,
  BUGS: true,
  BUHL: true,
  BUHR: true,
  BUIK: true,
  BUKE: true,
  BULB: true,
  BULK: true,
  BULL: true,
  BUMF: true,
  BUMP: true,
  BUMS: true,
  BUNA: true,
  BUND: true,
  BUNG: true,
  BUNK: true,
  BUNN: true,
  BUNS: true,
  BUNT: true,
  BUOY: true,
  BURA: true,
  BURB: true,
  BURD: true,
  BURG: true,
  BURK: true,
  BURL: true,
  BURN: true,
  BURP: true,
  BURR: true,
  BURS: true,
  BURY: true,
  BUSH: true,
  BUSK: true,
  BUSS: true,
  BUST: true,
  BUSY: true,
  BUTE: true,
  BUTS: true,
  BUTT: true,
  BUYS: true,
  BUZZ: true,
  BYDE: true,
  BYES: true,
  BYKE: true,
  BYRE: true,
  BYRL: true,
  BYTE: true,
  CAAS: true,
  CABA: true,
  CABS: true,
  CACA: true,
  CACK: true,
  CADE: true,
  CADI: true,
  CADS: true,
  CAFE: true,
  CAFF: true,
  CAFS: true,
  CAGE: true,
  CAGS: true,
  CAGY: true,
  CAID: true,
  CAIN: true,
  CAKE: true,
  CAKY: true,
  CALF: true,
  CALK: true,
  CALL: true,
  CALM: true,
  CALO: true,
  CALP: true,
  CALX: true,
  CAMA: true,
  CAME: true,
  CAMI: true,
  CAMO: true,
  CAMP: true,
  CAMS: true,
  CANE: true,
  CANG: true,
  CANN: true,
  CANS: true,
  CANT: true,
  CANY: true,
  CAPA: true,
  CAPE: true,
  CAPH: true,
  CAPI: true,
  CAPO: true,
  CAPS: true,
  CARB: true,
  CARD: true,
  CARE: true,
  CARK: true,
  CARL: true,
  CARN: true,
  CARP: true,
  CARR: true,
  CARS: true,
  CART: true,
  CASA: true,
  CASE: true,
  CASH: true,
  CASK: true,
  CAST: true,
  CATE: true,
  CATS: true,
  CAUF: true,
  CAUK: true,
  CAUL: true,
  CAUM: true,
  CAUP: true,
  CAVA: true,
  CAVE: true,
  CAVY: true,
  CAWK: true,
  CAWS: true,
  CAYS: true,
  CAZH: true,
  CEAS: true,
  CECA: true,
  CEDE: true,
  CEDI: true,
  CEES: true,
  CEIL: true,
  CELL: true,
  CELS: true,
  CELT: true,
  CENS: true,
  CENT: true,
  CEPE: true,
  CEPS: true,
  CERE: true,
  CERO: true,
  CERT: true,
  CESS: true,
  CETE: true,
  CHAD: true,
  CHAI: true,
  CHAL: true,
  CHAM: true,
  CHAO: true,
  CHAP: true,
  CHAR: true,
  CHAS: true,
  CHAT: true,
  CHAV: true,
  CHAW: true,
  CHAY: true,
  CHEF: true,
  CHEM: true,
  CHER: true,
  CHEW: true,
  CHEZ: true,
  CHIA: true,
  CHIB: true,
  CHIC: true,
  CHID: true,
  CHIK: true,
  CHIN: true,
  CHIP: true,
  CHIS: true,
  CHIT: true,
  CHIV: true,
  CHIZ: true,
  CHOC: true,
  CHOG: true,
  CHON: true,
  CHOP: true,
  CHOU: true,
  CHOW: true,
  CHUB: true,
  CHUG: true,
  CHUM: true,
  CHUR: true,
  CHUT: true,
  CIAO: true,
  CIDE: true,
  CIDS: true,
  CIEL: true,
  CIGS: true,
  CILL: true,
  CINE: true,
  CINQ: true,
  CION: true,
  CIRE: true,
  CIRL: true,
  CIST: true,
  CITE: true,
  CITO: true,
  CITS: true,
  CITY: true,
  CIVE: true,
  CLAD: true,
  CLAG: true,
  CLAM: true,
  CLAN: true,
  CLAP: true,
  CLAT: true,
  CLAW: true,
  CLAY: true,
  CLEF: true,
  CLEG: true,
  CLEM: true,
  CLEW: true,
  CLIP: true,
  CLIT: true,
  CLOD: true,
  CLOG: true,
  CLON: true,
  CLOP: true,
  CLOT: true,
  CLOU: true,
  CLOW: true,
  CLOY: true,
  CLUB: true,
  CLUE: true,
  COAL: true,
  COAT: true,
  COAX: true,
  COBB: true,
  COBS: true,
  COCA: true,
  COCH: true,
  COCK: true,
  COCO: true,
  CODA: true,
  CODE: true,
  CODS: true,
  COED: true,
  COFF: true,
  COFT: true,
  COGS: true,
  COHO: true,
  COIF: true,
  COIL: true,
  COIN: true,
  COIR: true,
  COIT: true,
  COKE: true,
  COKY: true,
  COLA: true,
  COLD: true,
  COLE: true,
  COLL: true,
  COLS: true,
  COLT: true,
  COLY: true,
  COMA: true,
  COMB: true,
  COME: true,
  COMM: true,
  COMP: true,
  COMS: true,
  COND: true,
  CONE: true,
  CONF: true,
  CONI: true,
  CONK: true,
  CONN: true,
  CONS: true,
  CONY: true,
  COOF: true,
  COOK: true,
  COOL: true,
  COOM: true,
  COON: true,
  COOP: true,
  COOS: true,
  COOT: true,
  COPE: true,
  COPS: true,
  COPY: true,
  CORD: true,
  CORE: true,
  CORF: true,
  CORK: true,
  CORM: true,
  CORN: true,
  CORS: true,
  CORY: true,
  COSE: true,
  COSH: true,
  COSS: true,
  COST: true,
  COSY: true,
  COTE: true,
  COTH: true,
  COTS: true,
  COTT: true,
  COUP: true,
  COUR: true,
  COVE: true,
  COWK: true,
  COWL: true,
  COWP: true,
  COWS: true,
  COWY: true,
  COXA: true,
  COXY: true,
  COYS: true,
  COZE: true,
  COZY: true,
  CRAB: true,
  CRAG: true,
  CRAM: true,
  CRAN: true,
  CRAP: true,
  CRAW: true,
  CRAY: true,
  CRED: true,
  CREE: true,
  CREM: true,
  CREW: true,
  CRIA: true,
  CRIB: true,
  CRIM: true,
  CRIP: true,
  CRIS: true,
  CRIT: true,
  CROC: true,
  CROG: true,
  CRON: true,
  CROP: true,
  CROW: true,
  CRUD: true,
  CRUE: true,
  CRUS: true,
  CRUX: true,
  CUBE: true,
  CUBS: true,
  CUDS: true,
  CUED: true,
  CUES: true,
  CUFF: true,
  CUIF: true,
  CUIT: true,
  CUKE: true,
  CULL: true,
  CULM: true,
  CULT: true,
  CUMS: true,
  CUNT: true,
  CUPS: true,
  CURB: true,
  CURD: true,
  CURE: true,
  CURF: true,
  CURL: true,
  CURN: true,
  CURR: true,
  CURS: true,
  CURT: true,
  CUSH: true,
  CUSK: true,
  CUSP: true,
  CUSS: true,
  CUTE: true,
  CUTS: true,
  CWMS: true,
  CYAN: true,
  CYMA: true,
  CYME: true,
  CYST: true,
  CYTE: true,
  CZAR: true,
  DAAL: true,
  DABS: true,
  DACE: true,
  DACK: true,
  DADA: true,
  DADO: true,
  DADS: true,
  DAES: true,
  DAFF: true,
  DAFT: true,
  DAGO: true,
  DAGS: true,
  DAHL: true,
  DAHS: true,
  DAIS: true,
  DAKS: true,
  DALE: true,
  DALI: true,
  DALS: true,
  DALT: true,
  DAME: true,
  DAMN: true,
  DAMP: true,
  DAMS: true,
  DANG: true,
  DANK: true,
  DANS: true,
  DANT: true,
  DAPS: true,
  DARB: true,
  DARE: true,
  DARG: true,
  DARI: true,
  DARK: true,
  DARN: true,
  DART: true,
  DASH: true,
  DATA: true,
  DATE: true,
  DATO: true,
  DAUB: true,
  DAUD: true,
  DAUR: true,
  DAUT: true,
  DAVY: true,
  DAWD: true,
  DAWK: true,
  DAWN: true,
  DAWS: true,
  DAWT: true,
  DAYS: true,
  DAZE: true,
  DEAD: true,
  DEAF: true,
  DEAL: true,
  DEAN: true,
  DEAR: true,
  DEAW: true,
  DEBE: true,
  DEBS: true,
  DEBT: true,
  DECK: true,
  DECO: true,
  DEED: true,
  DEEK: true,
  DEEM: true,
  DEEN: true,
  DEEP: true,
  DEER: true,
  DEES: true,
  DEET: true,
  DEEV: true,
  DEFI: true,
  DEFO: true,
  DEFT: true,
  DEFY: true,
  DEGS: true,
  DEGU: true,
  DEID: true,
  DEIF: true,
  DEIL: true,
  DEKE: true,
  DELE: true,
  DELF: true,
  DELI: true,
  DELL: true,
  DELO: true,
  DELS: true,
  DELT: true,
  DEME: true,
  DEMO: true,
  DEMY: true,
  DENE: true,
  DENI: true,
  DENS: true,
  DENT: true,
  DENY: true,
  DEPS: true,
  DERE: true,
  DERM: true,
  DERN: true,
  DERO: true,
  DERV: true,
  DESI: true,
  DESK: true,
  DEUS: true,
  DEVA: true,
  DEVI: true,
  DEVO: true,
  DEVS: true,
  DEWS: true,
  DEWY: true,
  DEXY: true,
  DEYS: true,
  DHAK: true,
  DHAL: true,
  DHOL: true,
  DHOW: true,
  DIAL: true,
  DIBS: true,
  DICE: true,
  DICH: true,
  DICK: true,
  DICT: true,
  DIDO: true,
  DIDY: true,
  DIEB: true,
  DIED: true,
  DIEL: true,
  DIES: true,
  DIET: true,
  DIFF: true,
  DIFS: true,
  DIGS: true,
  DIKA: true,
  DIKE: true,
  DILL: true,
  DIME: true,
  DIMP: true,
  DIMS: true,
  DINE: true,
  DING: true,
  DINK: true,
  DINO: true,
  DINS: true,
  DINT: true,
  DIOL: true,
  DIPS: true,
  DIPT: true,
  DIRE: true,
  DIRK: true,
  DIRL: true,
  DIRT: true,
  DISA: true,
  DISC: true,
  DISH: true,
  DISK: true,
  DISS: true,
  DITA: true,
  DITE: true,
  DITS: true,
  DITT: true,
  DITZ: true,
  DIVA: true,
  DIVE: true,
  DIVI: true,
  DIVO: true,
  DIVS: true,
  DIXI: true,
  DIXY: true,
  DIYA: true,
  DJIN: true,
  DOAB: true,
  DOAT: true,
  DOBE: true,
  DOBS: true,
  DOBY: true,
  DOCK: true,
  DOCO: true,
  DOCS: true,
  DOCU: true,
  DODO: true,
  DODS: true,
  DOEK: true,
  DOEN: true,
  DOER: true,
  DOES: true,
  DOFF: true,
  DOGE: true,
  DOGS: true,
  DOGY: true,
  DOHS: true,
  DOIT: true,
  DOJO: true,
  DOLE: true,
  DOLL: true,
  DOLS: true,
  DOLT: true,
  DOME: true,
  DOMS: true,
  DOMY: true,
  DONA: true,
  DONE: true,
  DONG: true,
  DONS: true,
  DOOB: true,
  DOOK: true,
  DOOL: true,
  DOOM: true,
  DOON: true,
  DOOR: true,
  DOOS: true,
  DOPA: true,
  DOPE: true,
  DOPS: true,
  DOPY: true,
  DORB: true,
  DORE: true,
  DORK: true,
  DORM: true,
  DORP: true,
  DORR: true,
  DORS: true,
  DORT: true,
  DORY: true,
  DOSA: true,
  DOSE: true,
  DOSH: true,
  DOSS: true,
  DOST: true,
  DOTE: true,
  DOTH: true,
  DOTS: true,
  DOTY: true,
  DOUC: true,
  DOUK: true,
  DOUM: true,
  DOUN: true,
  DOUP: true,
  DOUR: true,
  DOUT: true,
  DOUX: true,
  DOVE: true,
  DOWD: true,
  DOWF: true,
  DOWL: true,
  DOWN: true,
  DOWP: true,
  DOWS: true,
  DOWT: true,
  DOXY: true,
  DOYS: true,
  DOZE: true,
  DOZY: true,
  DRAB: true,
  DRAC: true,
  DRAD: true,
  DRAG: true,
  DRAM: true,
  DRAP: true,
  DRAT: true,
  DRAW: true,
  DRAY: true,
  DREE: true,
  DREG: true,
  DREK: true,
  DREW: true,
  DREY: true,
  DRIB: true,
  DRIP: true,
  DROP: true,
  DROW: true,
  DRUB: true,
  DRUG: true,
  DRUM: true,
  DRYS: true,
  DSOS: true,
  DUAD: true,
  DUAL: true,
  DUAN: true,
  DUAR: true,
  DUBS: true,
  DUCE: true,
  DUCI: true,
  DUCK: true,
  DUCT: true,
  DUDE: true,
  DUDS: true,
  DUED: true,
  DUEL: true,
  DUES: true,
  DUET: true,
  DUFF: true,
  DUGS: true,
  DUIT: true,
  DUKA: true,
  DUKE: true,
  DULE: true,
  DULL: true,
  DULY: true,
  DUMA: true,
  DUMB: true,
  DUMP: true,
  DUNE: true,
  DUNG: true,
  DUNK: true,
  DUNS: true,
  DUNT: true,
  DUOS: true,
  DUPE: true,
  DUPS: true,
  DURA: true,
  DURE: true,
  DURN: true,
  DURO: true,
  DURR: true,
  DUSH: true,
  DUSK: true,
  DUST: true,
  DUTY: true,
  DWAM: true,
  DYAD: true,
  DYED: true,
  DYER: true,
  DYES: true,
  DYKE: true,
  DYNE: true,
  DZHO: true,
  DZOS: true,
  EACH: true,
  EALE: true,
  EANS: true,
  EARD: true,
  EARL: true,
  EARN: true,
  EARS: true,
  EASE: true,
  EAST: true,
  EASY: true,
  EATH: true,
  EATS: true,
  EAUS: true,
  EAUX: true,
  EAVE: true,
  EBBS: true,
  EBON: true,
  ECAD: true,
  ECCE: true,
  ECCO: true,
  ECHE: true,
  ECHO: true,
  ECHT: true,
  ECOD: true,
  ECOS: true,
  ECRU: true,
  ECUS: true,
  EDDO: true,
  EDDY: true,
  EDGE: true,
  EDGY: true,
  EDHS: true,
  EDIT: true,
  EECH: true,
  EEEW: true,
  EELS: true,
  EELY: true,
  EERY: true,
  EEVN: true,
  EFFS: true,
  EFTS: true,
  EGAD: true,
  EGAL: true,
  EGER: true,
  EGGS: true,
  EGGY: true,
  EGIS: true,
  EGMA: true,
  EGOS: true,
  EHED: true,
  EIDE: true,
  EIKS: true,
  EILD: true,
  EINA: true,
  EINE: true,
  EISH: true,
  EKED: true,
  EKES: true,
  EKKA: true,
  ELAN: true,
  ELDS: true,
  ELFS: true,
  ELHI: true,
  ELKS: true,
  ELLS: true,
  ELMS: true,
  ELMY: true,
  ELSE: true,
  ELTS: true,
  EMES: true,
  EMEU: true,
  EMIC: true,
  EMIR: true,
  EMIT: true,
  EMMA: true,
  EMMY: true,
  EMOS: true,
  EMPT: true,
  EMUS: true,
  EMYD: true,
  EMYS: true,
  ENDS: true,
  ENES: true,
  ENEW: true,
  ENGS: true,
  ENOL: true,
  ENOW: true,
  ENTS: true,
  ENUF: true,
  ENVY: true,
  EOAN: true,
  EONS: true,
  EORL: true,
  EPEE: true,
  EPHA: true,
  EPIC: true,
  EPOS: true,
  ERAS: true,
  ERED: true,
  ERES: true,
  EREV: true,
  ERGO: true,
  ERGS: true,
  ERHU: true,
  ERIC: true,
  ERKS: true,
  ERNE: true,
  ERNS: true,
  EROS: true,
  ERRS: true,
  ERST: true,
  ERUV: true,
  ESES: true,
  ESKY: true,
  ESNE: true,
  ESPY: true,
  ESSE: true,
  ESTS: true,
  ETAS: true,
  ETAT: true,
  ETCH: true,
  ETEN: true,
  ETHE: true,
  ETHS: true,
  ETIC: true,
  ETNA: true,
  ETUI: true,
  EUGE: true,
  EUGH: true,
  EUKS: true,
  EUOI: true,
  EURO: true,
  EVEN: true,
  EVER: true,
  EVES: true,
  EVET: true,
  EVIL: true,
  EVOE: true,
  EVOS: true,
  EWER: true,
  EWES: true,
  EWKS: true,
  EWTS: true,
  EXAM: true,
  EXEC: true,
  EXED: true,
  EXES: true,
  EXIT: true,
  EXON: true,
  EXPO: true,
  EXUL: true,
  EYAS: true,
  EYED: true,
  EYEN: true,
  EYER: true,
  EYES: true,
  EYNE: true,
  EYOT: true,
  EYRA: true,
  EYRE: true,
  EYRY: true,
  FAAN: true,
  FAAS: true,
  FABS: true,
  FACE: true,
  FACT: true,
  FADE: true,
  FADO: true,
  FADS: true,
  FADY: true,
  FAFF: true,
  FAGS: true,
  FAHS: true,
  FAIK: true,
  FAIL: true,
  FAIN: true,
  FAIR: true,
  FAIX: true,
  FAKE: true,
  FALL: true,
  FALX: true,
  FAME: true,
  FAND: true,
  FANE: true,
  FANG: true,
  FANK: true,
  FANO: true,
  FANS: true,
  FARD: true,
  FARE: true,
  FARL: true,
  FARM: true,
  FARO: true,
  FARS: true,
  FART: true,
  FASH: true,
  FAST: true,
  FATE: true,
  FATS: true,
  FAUN: true,
  FAUR: true,
  FAUT: true,
  FAUX: true,
  FAVA: true,
  FAVE: true,
  FAWN: true,
  FAWS: true,
  FAYS: true,
  FAZE: true,
  FEAL: true,
  FEAR: true,
  FEAT: true,
  FECK: true,
  FEDS: true,
  FEEB: true,
  FEED: true,
  FEEL: true,
  FEEN: true,
  FEER: true,
  FEES: true,
  FEET: true,
  FEGS: true,
  FEHM: true,
  FEHS: true,
  FEIS: true,
  FELL: true,
  FELT: true,
  FEME: true,
  FEMS: true,
  FEND: true,
  FENI: true,
  FENS: true,
  FENT: true,
  FEOD: true,
  FERE: true,
  FERM: true,
  FERN: true,
  FESS: true,
  FEST: true,
  FETA: true,
  FETE: true,
  FETS: true,
  FETT: true,
  FEUD: true,
  FEUS: true,
  FEWS: true,
  FEYS: true,
  FIAR: true,
  FIAT: true,
  FIBS: true,
  FICE: true,
  FICO: true,
  FIDO: true,
  FIDS: true,
  FIEF: true,
  FIER: true,
  FIFE: true,
  FIGO: true,
  FIGS: true,
  FIKE: true,
  FIKY: true,
  FILA: true,
  FILE: true,
  FILK: true,
  FILL: true,
  FILM: true,
  FILO: true,
  FILS: true,
  FIND: true,
  FINE: true,
  FINI: true,
  FINK: true,
  FINO: true,
  FINS: true,
  FIQH: true,
  FIRE: true,
  FIRK: true,
  FIRM: true,
  FIRN: true,
  FIRS: true,
  FISC: true,
  FISH: true,
  FISK: true,
  FIST: true,
  FITS: true,
  FITT: true,
  FIVE: true,
  FIXT: true,
  FIZZ: true,
  FLAB: true,
  FLAG: true,
  FLAK: true,
  FLAM: true,
  FLAN: true,
  FLAP: true,
  FLAT: true,
  FLAW: true,
  FLAX: true,
  FLAY: true,
  FLEA: true,
  FLED: true,
  FLEE: true,
  FLEG: true,
  FLEW: true,
  FLEX: true,
  FLEY: true,
  FLIC: true,
  FLIM: true,
  FLIP: true,
  FLIR: true,
  FLIT: true,
  FLIX: true,
  FLOB: true,
  FLOC: true,
  FLOE: true,
  FLOG: true,
  FLOP: true,
  FLOR: true,
  FLOW: true,
  FLOX: true,
  FLUB: true,
  FLUE: true,
  FLUS: true,
  FLUX: true,
  FOAL: true,
  FOAM: true,
  FOBS: true,
  FOCI: true,
  FOEN: true,
  FOES: true,
  FOGS: true,
  FOGY: true,
  FOHN: true,
  FOID: true,
  FOIL: true,
  FOIN: true,
  FOLD: true,
  FOLK: true,
  FOND: true,
  FONE: true,
  FONS: true,
  FONT: true,
  FOOD: true,
  FOOL: true,
  FOOS: true,
  FOOT: true,
  FOPS: true,
  FORA: true,
  FORB: true,
  FORD: true,
  FORE: true,
  FORK: true,
  FORM: true,
  FORT: true,
  FOSS: true,
  FOUD: true,
  FOUL: true,
  FOUR: true,
  FOUS: true,
  FOWL: true,
  FOXY: true,
  FOYS: true,
  FOZY: true,
  FRAB: true,
  FRAE: true,
  FRAG: true,
  FRAP: true,
  FRAS: true,
  FRAT: true,
  FRAU: true,
  FRAY: true,
  FREE: true,
  FRET: true,
  FRIB: true,
  FRIG: true,
  FRIS: true,
  FRIT: true,
  FRIZ: true,
  FROE: true,
  FROG: true,
  FROM: true,
  FROS: true,
  FROW: true,
  FRUG: true,
  FUBS: true,
  FUCI: true,
  FUCK: true,
  FUDS: true,
  FUEL: true,
  FUFF: true,
  FUGS: true,
  FUGU: true,
  FUJI: true,
  FULL: true,
  FUME: true,
  FUMS: true,
  FUMY: true,
  FUND: true,
  FUNG: true,
  FUNK: true,
  FUNS: true,
  FURL: true,
  FURR: true,
  FURS: true,
  FURY: true,
  FUSC: true,
  FUSE: true,
  FUSK: true,
  FUSS: true,
  FUST: true,
  FUTZ: true,
  FUZE: true,
  FUZZ: true,
  FYCE: true,
  FYKE: true,
  FYLE: true,
  FYRD: true,
  GABS: true,
  GABY: true,
  GACH: true,
  GADE: true,
  GADI: true,
  GADS: true,
  GAED: true,
  GAEN: true,
  GAES: true,
  GAFF: true,
  GAGA: true,
  GAGE: true,
  GAGS: true,
  GAID: true,
  GAIN: true,
  GAIR: true,
  GAIT: true,
  GAJO: true,
  GAKS: true,
  GALA: true,
  GALE: true,
  GALL: true,
  GALS: true,
  GAMA: true,
  GAMB: true,
  GAME: true,
  GAMP: true,
  GAMS: true,
  GAMY: true,
  GANE: true,
  GANG: true,
  GANS: true,
  GANT: true,
  GAOL: true,
  GAPE: true,
  GAPO: true,
  GAPS: true,
  GAPY: true,
  GARB: true,
  GARE: true,
  GARI: true,
  GARS: true,
  GART: true,
  GASH: true,
  GASP: true,
  GAST: true,
  GATE: true,
  GATH: true,
  GATS: true,
  GAUD: true,
  GAUM: true,
  GAUN: true,
  GAUP: true,
  GAUR: true,
  GAUS: true,
  GAVE: true,
  GAWD: true,
  GAWK: true,
  GAWP: true,
  GAWS: true,
  GAYS: true,
  GAZE: true,
  GAZY: true,
  GEAL: true,
  GEAN: true,
  GEAR: true,
  GEAT: true,
  GECK: true,
  GEDS: true,
  GEED: true,
  GEEK: true,
  GEEP: true,
  GEES: true,
  GEEZ: true,
  GEIT: true,
  GELD: true,
  GELS: true,
  GELT: true,
  GEMS: true,
  GENA: true,
  GENE: true,
  GENS: true,
  GENT: true,
  GENU: true,
  GEOS: true,
  GERE: true,
  GERM: true,
  GERS: true,
  GERT: true,
  GEST: true,
  GETA: true,
  GETS: true,
  GEUM: true,
  GHAT: true,
  GHEE: true,
  GHIS: true,
  GIBE: true,
  GIBS: true,
  GIDS: true,
  GIED: true,
  GIEN: true,
  GIES: true,
  GIFS: true,
  GIFT: true,
  GIGA: true,
  GIGS: true,
  GILA: true,
  GILD: true,
  GILL: true,
  GILT: true,
  GIMP: true,
  GING: true,
  GINK: true,
  GINN: true,
  GINS: true,
  GIOS: true,
  GIPS: true,
  GIRD: true,
  GIRL: true,
  GIRN: true,
  GIRO: true,
  GIRR: true,
  GIRT: true,
  GISM: true,
  GIST: true,
  GITE: true,
  GITS: true,
  GIVE: true,
  GIZZ: true,
  GJUS: true,
  GLAD: true,
  GLAM: true,
  GLED: true,
  GLEE: true,
  GLEG: true,
  GLEI: true,
  GLEN: true,
  GLEY: true,
  GLIA: true,
  GLIB: true,
  GLID: true,
  GLIM: true,
  GLIT: true,
  GLOB: true,
  GLOM: true,
  GLOP: true,
  GLOW: true,
  GLUE: true,
  GLUG: true,
  GLUM: true,
  GLUT: true,
  GNAR: true,
  GNAT: true,
  GNAW: true,
  GNOW: true,
  GNUS: true,
  GOAD: true,
  GOAF: true,
  GOAL: true,
  GOAS: true,
  GOAT: true,
  GOBI: true,
  GOBO: true,
  GOBS: true,
  GOBY: true,
  GODS: true,
  GOEL: true,
  GOER: true,
  GOES: true,
  GOEY: true,
  GOFF: true,
  GOGO: true,
  GOJI: true,
  GOLD: true,
  GOLE: true,
  GOLF: true,
  GOLP: true,
  GONE: true,
  GONG: true,
  GONK: true,
  GONS: true,
  GOOD: true,
  GOOF: true,
  GOOG: true,
  GOOK: true,
  GOOL: true,
  GOON: true,
  GOOP: true,
  GOOR: true,
  GOOS: true,
  GORA: true,
  GORE: true,
  GORI: true,
  GORM: true,
  GORP: true,
  GORS: true,
  GORY: true,
  GOSH: true,
  GOSS: true,
  GOTH: true,
  GOUK: true,
  GOUT: true,
  GOVS: true,
  GOWD: true,
  GOWF: true,
  GOWK: true,
  GOWL: true,
  GOWN: true,
  GOYS: true,
  GRAB: true,
  GRAD: true,
  GRAM: true,
  GRAN: true,
  GRAT: true,
  GRAV: true,
  GRAY: true,
  GREE: true,
  GREN: true,
  GREW: true,
  GREX: true,
  GREY: true,
  GRID: true,
  GRIG: true,
  GRIM: true,
  GRIN: true,
  GRIP: true,
  GRIS: true,
  GRIT: true,
  GRIZ: true,
  GROG: true,
  GROK: true,
  GROT: true,
  GROW: true,
  GRRL: true,
  GRUB: true,
  GRUE: true,
  GRUM: true,
  GUAN: true,
  GUAR: true,
  GUBS: true,
  GUCK: true,
  GUDE: true,
  GUES: true,
  GUFF: true,
  GUGA: true,
  GUID: true,
  GULA: true,
  GULE: true,
  GULF: true,
  GULL: true,
  GULP: true,
  GULS: true,
  GULY: true,
  GUMP: true,
  GUMS: true,
  GUNG: true,
  GUNK: true,
  GUNS: true,
  GUPS: true,
  GURL: true,
  GURN: true,
  GURS: true,
  GURU: true,
  GUSH: true,
  GUST: true,
  GUTS: true,
  GUVS: true,
  GUYS: true,
  GYAL: true,
  GYBE: true,
  GYMP: true,
  GYMS: true,
  GYNO: true,
  GYNY: true,
  GYPO: true,
  GYPS: true,
  GYRE: true,
  GYRI: true,
  GYRO: true,
  GYTE: true,
  GYVE: true,
  HAAF: true,
  HAAR: true,
  HABU: true,
  HACK: true,
  HADE: true,
  HADJ: true,
  HADS: true,
  HAED: true,
  HAEM: true,
  HAEN: true,
  HAES: true,
  HAET: true,
  HAFF: true,
  HAFT: true,
  HAGG: true,
  HAGS: true,
  HAHA: true,
  HAHS: true,
  HAIK: true,
  HAIL: true,
  HAIN: true,
  HAIR: true,
  HAJI: true,
  HAJJ: true,
  HAKA: true,
  HAKE: true,
  HAKU: true,
  HALE: true,
  HALF: true,
  HALL: true,
  HALM: true,
  HALO: true,
  HALT: true,
  HAME: true,
  HAMS: true,
  HAND: true,
  HANG: true,
  HANK: true,
  HANT: true,
  HAOS: true,
  HAPS: true,
  HAPU: true,
  HARD: true,
  HARE: true,
  HARK: true,
  HARL: true,
  HARM: true,
  HARN: true,
  HARO: true,
  HARP: true,
  HART: true,
  HASH: true,
  HASK: true,
  HASP: true,
  HASS: true,
  HAST: true,
  HATE: true,
  HATH: true,
  HATS: true,
  HAUD: true,
  HAUF: true,
  HAUL: true,
  HAUN: true,
  HAUT: true,
  HAVE: true,
  HAWK: true,
  HAWM: true,
  HAWS: true,
  HAYS: true,
  HAZE: true,
  HAZY: true,
  HEAD: true,
  HEAL: true,
  HEAP: true,
  HEAR: true,
  HEAT: true,
  HEBE: true,
  HECH: true,
  HECK: true,
  HEED: true,
  HEEL: true,
  HEFT: true,
  HEHS: true,
  HEID: true,
  HEIL: true,
  HEIR: true,
  HELD: true,
  HELE: true,
  HELL: true,
  HELM: true,
  HELO: true,
  HELP: true,
  HEME: true,
  HEMP: true,
  HEMS: true,
  HEND: true,
  HENS: true,
  HENT: true,
  HEPS: true,
  HEPT: true,
  HERB: true,
  HERD: true,
  HERE: true,
  HERL: true,
  HERM: true,
  HERN: true,
  HERO: true,
  HERS: true,
  HERY: true,
  HESP: true,
  HEST: true,
  HETE: true,
  HETH: true,
  HETS: true,
  HEWN: true,
  HEWS: true,
  HEYS: true,
  HICK: true,
  HIDE: true,
  HIED: true,
  HIES: true,
  HIGH: true,
  HIKE: true,
  HILA: true,
  HILD: true,
  HILI: true,
  HILL: true,
  HILT: true,
  HIMS: true,
  HIND: true,
  HING: true,
  HINS: true,
  HINT: true,
  HIOI: true,
  HIPS: true,
  HIPT: true,
  HIRE: true,
  HISH: true,
  HISN: true,
  HISS: true,
  HIST: true,
  HITS: true,
  HIVE: true,
  HIYA: true,
  HIZZ: true,
  HMMM: true,
  HOAR: true,
  HOAS: true,
  HOAX: true,
  HOBO: true,
  HOBS: true,
  HOCK: true,
  HODS: true,
  HOED: true,
  HOER: true,
  HOES: true,
  HOGG: true,
  HOGH: true,
  HOGS: true,
  HOHA: true,
  HOHS: true,
  HOIK: true,
  HOIS: true,
  HOKA: true,
  HOKE: true,
  HOKI: true,
  HOLD: true,
  HOLE: true,
  HOLK: true,
  HOLM: true,
  HOLO: true,
  HOLP: true,
  HOLS: true,
  HOLT: true,
  HOLY: true,
  HOMA: true,
  HOME: true,
  HOMO: true,
  HOMS: true,
  HOMY: true,
  HOND: true,
  HONE: true,
  HONG: true,
  HONK: true,
  HONS: true,
  HOOD: true,
  HOOF: true,
  HOOK: true,
  HOON: true,
  HOOP: true,
  HOOR: true,
  HOOT: true,
  HOPE: true,
  HOPS: true,
  HORA: true,
  HORE: true,
  HORI: true,
  HORK: true,
  HORN: true,
  HORS: true,
  HOSE: true,
  HOSS: true,
  HOST: true,
  HOTE: true,
  HOTS: true,
  HOUF: true,
  HOUR: true,
  HOUT: true,
  HOVE: true,
  HOWE: true,
  HOWF: true,
  HOWK: true,
  HOWL: true,
  HOWS: true,
  HOYA: true,
  HOYS: true,
  HUBS: true,
  HUCK: true,
  HUED: true,
  HUER: true,
  HUES: true,
  HUFF: true,
  HUGE: true,
  HUGS: true,
  HUGY: true,
  HUHU: true,
  HUIA: true,
  HUIC: true,
  HUIS: true,
  HULA: true,
  HULE: true,
  HULK: true,
  HULL: true,
  HUMA: true,
  HUMF: true,
  HUMP: true,
  HUMS: true,
  HUNG: true,
  HUNH: true,
  HUNK: true,
  HUNS: true,
  HUNT: true,
  HUPS: true,
  HURL: true,
  HURT: true,
  HUSH: true,
  HUSK: true,
  HUSO: true,
  HUSS: true,
  HUTS: true,
  HWAN: true,
  HWYL: true,
  HYED: true,
  HYEN: true,
  HYES: true,
  HYKE: true,
  HYLA: true,
  HYLE: true,
  HYMN: true,
  HYPE: true,
  HYPO: true,
  HYPS: true,
  HYTE: true,
  IAMB: true,
  IBEX: true,
  IBIS: true,
  ICED: true,
  ICER: true,
  ICES: true,
  ICHS: true,
  ICKS: true,
  ICKY: true,
  ICON: true,
  IDEA: true,
  IDEE: true,
  IDEM: true,
  IDES: true,
  IDLE: true,
  IDLY: true,
  IDOL: true,
  IDYL: true,
  IFFY: true,
  IGAD: true,
  IGGS: true,
  IGLU: true,
  IKAN: true,
  IKAT: true,
  IKON: true,
  ILEA: true,
  ILEX: true,
  ILIA: true,
  ILKA: true,
  ILKS: true,
  ILLS: true,
  ILLY: true,
  IMAM: true,
  IMID: true,
  IMMY: true,
  IMPI: true,
  IMPS: true,
  INBY: true,
  INCH: true,
  INFO: true,
  INGO: true,
  INGS: true,
  INIA: true,
  INKS: true,
  INKY: true,
  INLY: true,
  INNS: true,
  INRO: true,
  INTI: true,
  INTO: true,
  IONS: true,
  IOTA: true,
  IRED: true,
  IRES: true,
  IRID: true,
  IRIS: true,
  IRKS: true,
  IRON: true,
  ISBA: true,
  ISIT: true,
  ISLE: true,
  ISMS: true,
  ISNA: true,
  ISOS: true,
  ITAS: true,
  ITCH: true,
  ITEM: true,
  IURE: true,
  IWIS: true,
  IXIA: true,
  IZAR: true,
  JAAP: true,
  JABS: true,
  JACK: true,
  JADE: true,
  JAFA: true,
  JAGA: true,
  JAGG: true,
  JAGS: true,
  JAIL: true,
  JAKE: true,
  JAKS: true,
  JAMB: true,
  JAMS: true,
  JANE: true,
  JANN: true,
  JAPE: true,
  JAPS: true,
  JARK: true,
  JARL: true,
  JARP: true,
  JARS: true,
  JASP: true,
  JASS: true,
  JASY: true,
  JATO: true,
  JAUK: true,
  JAUP: true,
  JAVA: true,
  JAWS: true,
  JAXY: true,
  JAYS: true,
  JAZY: true,
  JAZZ: true,
  JEAN: true,
  JEAT: true,
  JEDI: true,
  JEED: true,
  JEEL: true,
  JEEP: true,
  JEER: true,
  JEES: true,
  JEEZ: true,
  JEFE: true,
  JEFF: true,
  JEHU: true,
  JELL: true,
  JEON: true,
  JERK: true,
  JESS: true,
  JEST: true,
  JETE: true,
  JETS: true,
  JEUX: true,
  JEWS: true,
  JIAO: true,
  JIBB: true,
  JIBE: true,
  JIBS: true,
  JIFF: true,
  JIGS: true,
  JILL: true,
  JILT: true,
  JIMP: true,
  JINK: true,
  JINN: true,
  JINS: true,
  JINX: true,
  JIRD: true,
  JISM: true,
  JIVE: true,
  JIVY: true,
  JIZZ: true,
  JOBE: true,
  JOBS: true,
  JOCK: true,
  JOCO: true,
  JOES: true,
  JOEY: true,
  JOGS: true,
  JOHN: true,
  JOIN: true,
  JOKE: true,
  JOKY: true,
  JOLE: true,
  JOLL: true,
  JOLS: true,
  JOLT: true,
  JOMO: true,
  JONG: true,
  JOOK: true,
  JORS: true,
  JOSH: true,
  JOSS: true,
  JOTA: true,
  JOTS: true,
  JOUK: true,
  JOUR: true,
  JOWL: true,
  JOWS: true,
  JOYS: true,
  JUBA: true,
  JUBE: true,
  JUCO: true,
  JUDO: true,
  JUDS: true,
  JUDY: true,
  JUGA: true,
  JUGS: true,
  JUJU: true,
  JUKE: true,
  JUKU: true,
  JUMP: true,
  JUNK: true,
  JUPE: true,
  JURA: true,
  JURE: true,
  JURY: true,
  JUST: true,
  JUTE: true,
  JUTS: true,
  JUVE: true,
  JYNX: true,
  KAAL: true,
  KAAS: true,
  KABS: true,
  KACK: true,
  KADE: true,
  KADI: true,
  KAED: true,
  KAES: true,
  KAFS: true,
  KAGO: true,
  KAGU: true,
  KAID: true,
  KAIE: true,
  KAIF: true,
  KAIK: true,
  KAIL: true,
  KAIM: true,
  KAIN: true,
  KAIS: true,
  KAKA: true,
  KAKI: true,
  KAKS: true,
  KALE: true,
  KALI: true,
  KAMA: true,
  KAME: true,
  KAMI: true,
  KANA: true,
  KANE: true,
  KANG: true,
  KANS: true,
  KANT: true,
  KAON: true,
  KAPA: true,
  KAPH: true,
  KAPU: true,
  KARA: true,
  KARK: true,
  KARN: true,
  KARO: true,
  KART: true,
  KATA: true,
  KATI: true,
  KATS: true,
  KAVA: true,
  KAWA: true,
  KAWS: true,
  KAYO: true,
  KAYS: true,
  KAZI: true,
  KBAR: true,
  KEAS: true,
  KEBS: true,
  KECK: true,
  KEDS: true,
  KEEF: true,
  KEEK: true,
  KEEL: true,
  KEEN: true,
  KEEP: true,
  KEET: true,
  KEFS: true,
  KEGS: true,
  KEIR: true,
  KEKS: true,
  KELL: true,
  KELP: true,
  KELT: true,
  KEMB: true,
  KEMP: true,
  KENO: true,
  KENS: true,
  KENT: true,
  KEPI: true,
  KEPS: true,
  KEPT: true,
  KERB: true,
  KERF: true,
  KERN: true,
  KERO: true,
  KESH: true,
  KEST: true,
  KETA: true,
  KETE: true,
  KETO: true,
  KETS: true,
  KEWL: true,
  KEYS: true,
  KHAF: true,
  KHAN: true,
  KHAT: true,
  KHET: true,
  KHIS: true,
  KHOR: true,
  KHUD: true,
  KIBE: true,
  KICK: true,
  KIDS: true,
  KIEF: true,
  KIER: true,
  KIEV: true,
  KIFF: true,
  KIFS: true,
  KIKE: true,
  KILD: true,
  KILL: true,
  KILN: true,
  KILO: true,
  KILP: true,
  KILT: true,
  KINA: true,
  KIND: true,
  KINE: true,
  KING: true,
  KINK: true,
  KINO: true,
  KINS: true,
  KIPE: true,
  KIPP: true,
  KIPS: true,
  KIRK: true,
  KIRN: true,
  KIRS: true,
  KISH: true,
  KISS: true,
  KIST: true,
  KITE: true,
  KITH: true,
  KITS: true,
  KIVA: true,
  KIWI: true,
  KLAP: true,
  KLIK: true,
  KNAG: true,
  KNAP: true,
  KNAR: true,
  KNEE: true,
  KNEW: true,
  KNIT: true,
  KNOB: true,
  KNOP: true,
  KNOT: true,
  KNOW: true,
  KNUB: true,
  KNUR: true,
  KNUT: true,
  KOAN: true,
  KOAP: true,
  KOAS: true,
  KOBO: true,
  KOBS: true,
  KOEL: true,
  KOFF: true,
  KOHA: true,
  KOHL: true,
  KOIS: true,
  KOJI: true,
  KOKA: true,
  KOLA: true,
  KOLO: true,
  KOND: true,
  KONK: true,
  KONS: true,
  KOOK: true,
  KOPH: true,
  KOPS: true,
  KORA: true,
  KORE: true,
  KORO: true,
  KORS: true,
  KORU: true,
  KOSS: true,
  KOTO: true,
  KOWS: true,
  KRAB: true,
  KRAI: true,
  KRAY: true,
  KRIS: true,
  KSAR: true,
  KUDO: true,
  KUDU: true,
  KUEH: true,
  KUES: true,
  KUFI: true,
  KUIA: true,
  KUKU: true,
  KULA: true,
  KUNA: true,
  KUNE: true,
  KURI: true,
  KURU: true,
  KUTA: true,
  KUTI: true,
  KUTU: true,
  KUZU: true,
  KVAS: true,
  KYAK: true,
  KYAR: true,
  KYAT: true,
  KYBO: true,
  KYES: true,
  KYLE: true,
  KYND: true,
  KYNE: true,
  KYPE: true,
  KYTE: true,
  KYUS: true,
  LABS: true,
  LACE: true,
  LACK: true,
  LACS: true,
  LACY: true,
  LADE: true,
  LADS: true,
  LADY: true,
  LAER: true,
  LAGS: true,
  LAHS: true,
  LAIC: true,
  LAID: true,
  LAIK: true,
  LAIN: true,
  LAIR: true,
  LAKE: true,
  LAKH: true,
  LAKY: true,
  LALL: true,
  LAMA: true,
  LAMB: true,
  LAME: true,
  LAMP: true,
  LAMS: true,
  LANA: true,
  LAND: true,
  LANE: true,
  LANG: true,
  LANK: true,
  LANT: true,
  LANX: true,
  LAPS: true,
  LARD: true,
  LARE: true,
  LARI: true,
  LARK: true,
  LARN: true,
  LARS: true,
  LASE: true,
  LASH: true,
  LASS: true,
  LAST: true,
  LATE: true,
  LATH: true,
  LATI: true,
  LATS: true,
  LATU: true,
  LAUD: true,
  LAUF: true,
  LAVA: true,
  LAVE: true,
  LAVS: true,
  LAWK: true,
  LAWN: true,
  LAWS: true,
  LAYS: true,
  LAZE: true,
  LAZO: true,
  LAZY: true,
  LEAD: true,
  LEAF: true,
  LEAK: true,
  LEAL: true,
  LEAM: true,
  LEAN: true,
  LEAP: true,
  LEAR: true,
  LEAS: true,
  LEAT: true,
  LECH: true,
  LEDE: true,
  LEED: true,
  LEEK: true,
  LEEP: true,
  LEER: true,
  LEES: true,
  LEET: true,
  LEFT: true,
  LEGS: true,
  LEHR: true,
  LEIR: true,
  LEIS: true,
  LEKE: true,
  LEKS: true,
  LEKU: true,
  LEME: true,
  LEND: true,
  LENG: true,
  LENO: true,
  LENS: true,
  LENT: true,
  LEPS: true,
  LEPT: true,
  LERE: true,
  LERP: true,
  LESS: true,
  LEST: true,
  LETS: true,
  LEUD: true,
  LEVA: true,
  LEVE: true,
  LEVO: true,
  LEVS: true,
  LEVY: true,
  LEWD: true,
  LEYS: true,
  LEZZ: true,
  LIAR: true,
  LIAS: true,
  LIBS: true,
  LICE: true,
  LICH: true,
  LICK: true,
  LIDO: true,
  LIDS: true,
  LIED: true,
  LIEF: true,
  LIEN: true,
  LIER: true,
  LIES: true,
  LIEU: true,
  LIFE: true,
  LIFT: true,
  LIGS: true,
  LIKE: true,
  LILL: true,
  LILO: true,
  LILT: true,
  LILY: true,
  LIMA: true,
  LIMB: true,
  LIME: true,
  LIMN: true,
  LIMO: true,
  LIMP: true,
  LIMY: true,
  LIND: true,
  LINE: true,
  LING: true,
  LINK: true,
  LINN: true,
  LINO: true,
  LINS: true,
  LINT: true,
  LINY: true,
  LION: true,
  LIPA: true,
  LIPE: true,
  LIPO: true,
  LIPS: true,
  LIRA: true,
  LIRE: true,
  LIRI: true,
  LIRK: true,
  LISK: true,
  LISP: true,
  LIST: true,
  LITE: true,
  LITH: true,
  LITS: true,
  LITU: true,
  LIVE: true,
  LOAD: true,
  LOAF: true,
  LOAM: true,
  LOAN: true,
  LOBE: true,
  LOBI: true,
  LOBO: true,
  LOBS: true,
  LOCA: true,
  LOCH: true,
  LOCI: true,
  LOCK: true,
  LOCO: true,
  LODE: true,
  LODS: true,
  LOFT: true,
  LOGE: true,
  LOGO: true,
  LOGS: true,
  LOGY: true,
  LOID: true,
  LOIN: true,
  LOIR: true,
  LOKE: true,
  LOLL: true,
  LOLZ: true,
  LOMA: true,
  LOME: true,
  LONE: true,
  LONG: true,
  LOOF: true,
  LOOK: true,
  LOOM: true,
  LOON: true,
  LOOP: true,
  LOOR: true,
  LOOS: true,
  LOOT: true,
  LOPE: true,
  LOPS: true,
  LORD: true,
  LORE: true,
  LORN: true,
  LORY: true,
  LOSE: true,
  LOSH: true,
  LOSS: true,
  LOST: true,
  LOTA: true,
  LOTE: true,
  LOTH: true,
  LOTI: true,
  LOTO: true,
  LOTS: true,
  LOUD: true,
  LOUN: true,
  LOUP: true,
  LOUR: true,
  LOUS: true,
  LOUT: true,
  LOVE: true,
  LOWE: true,
  LOWN: true,
  LOWP: true,
  LOWS: true,
  LOWT: true,
  LOYS: true,
  LUAU: true,
  LUBE: true,
  LUCE: true,
  LUCK: true,
  LUDE: true,
  LUDO: true,
  LUDS: true,
  LUES: true,
  LUFF: true,
  LUGE: true,
  LUGS: true,
  LUIT: true,
  LUKE: true,
  LULL: true,
  LULU: true,
  LULZ: true,
  LUMA: true,
  LUMP: true,
  LUMS: true,
  LUNA: true,
  LUNE: true,
  LUNG: true,
  LUNK: true,
  LUNS: true,
  LUNT: true,
  LUNY: true,
  LURE: true,
  LURK: true,
  LURS: true,
  LUSH: true,
  LUSK: true,
  LUST: true,
  LUTE: true,
  LUTZ: true,
  LUVS: true,
  LUXE: true,
  LWEI: true,
  LYAM: true,
  LYCH: true,
  LYES: true,
  LYME: true,
  LYMS: true,
  LYNE: true,
  LYNX: true,
  LYRA: true,
  LYRE: true,
  LYSE: true,
  LYTE: true,
  MAAR: true,
  MAAS: true,
  MABE: true,
  MACE: true,
  MACH: true,
  MACK: true,
  MACS: true,
  MADE: true,
  MADS: true,
  MAES: true,
  MAGE: true,
  MAGG: true,
  MAGI: true,
  MAGS: true,
  MAHA: true,
  MAID: true,
  MAIK: true,
  MAIL: true,
  MAIM: true,
  MAIN: true,
  MAIR: true,
  MAKE: true,
  MAKI: true,
  MAKO: true,
  MAKS: true,
  MALA: true,
  MALE: true,
  MALI: true,
  MALL: true,
  MALM: true,
  MALS: true,
  MALT: true,
  MAMA: true,
  MAMS: true,
  MANA: true,
  MAND: true,
  MANE: true,
  MANG: true,
  MANI: true,
  MANO: true,
  MANS: true,
  MANY: true,
  MAPS: true,
  MARA: true,
  MARC: true,
  MARD: true,
  MARE: true,
  MARG: true,
  MARK: true,
  MARL: true,
  MARM: true,
  MARS: true,
  MART: true,
  MARY: true,
  MASA: true,
  MASE: true,
  MASH: true,
  MASK: true,
  MASS: true,
  MAST: true,
  MASU: true,
  MATE: true,
  MATH: true,
  MATS: true,
  MATT: true,
  MATY: true,
  MAUD: true,
  MAUL: true,
  MAUN: true,
  MAUT: true,
  MAWK: true,
  MAWN: true,
  MAWR: true,
  MAWS: true,
  MAXI: true,
  MAYA: true,
  MAYO: true,
  MAYS: true,
  MAZE: true,
  MAZY: true,
  MEAD: true,
  MEAL: true,
  MEAN: true,
  MEAT: true,
  MECH: true,
  MECK: true,
  MEDS: true,
  MEED: true,
  MEEK: true,
  MEER: true,
  MEES: true,
  MEET: true,
  MEFF: true,
  MEGA: true,
  MEGS: true,
  MEIN: true,
  MELA: true,
  MELD: true,
  MELL: true,
  MELS: true,
  MELT: true,
  MEME: true,
  MEMO: true,
  MEMS: true,
  MEND: true,
  MENE: true,
  MENG: true,
  MENO: true,
  MENT: true,
  MENU: true,
  MEOU: true,
  MEOW: true,
  MERC: true,
  MERE: true,
  MERI: true,
  MERK: true,
  MERL: true,
  MESA: true,
  MESE: true,
  MESH: true,
  MESS: true,
  META: true,
  METE: true,
  METH: true,
  METS: true,
  MEUS: true,
  MEVE: true,
  MEWL: true,
  MEWS: true,
  MEZE: true,
  MEZZ: true,
  MHOS: true,
  MIBS: true,
  MICA: true,
  MICE: true,
  MICH: true,
  MICK: true,
  MICO: true,
  MICS: true,
  MIDI: true,
  MIDS: true,
  MIEN: true,
  MIFF: true,
  MIGG: true,
  MIGS: true,
  MIHA: true,
  MIHI: true,
  MIKE: true,
  MILD: true,
  MILE: true,
  MILF: true,
  MILK: true,
  MILL: true,
  MILO: true,
  MILS: true,
  MILT: true,
  MIME: true,
  MINA: true,
  MIND: true,
  MINE: true,
  MING: true,
  MINI: true,
  MINK: true,
  MINO: true,
  MINT: true,
  MINX: true,
  MINY: true,
  MIPS: true,
  MIRE: true,
  MIRI: true,
  MIRK: true,
  MIRO: true,
  MIRS: true,
  MIRV: true,
  MIRY: true,
  MISE: true,
  MISO: true,
  MISS: true,
  MIST: true,
  MITE: true,
  MITT: true,
  MITY: true,
  MIXT: true,
  MIXY: true,
  MIZZ: true,
  MNAS: true,
  MOAI: true,
  MOAN: true,
  MOAS: true,
  MOAT: true,
  MOBE: true,
  MOBS: true,
  MOBY: true,
  MOCH: true,
  MOCK: true,
  MOCS: true,
  MODE: true,
  MODI: true,
  MODS: true,
  MOER: true,
  MOES: true,
  MOFO: true,
  MOGS: true,
  MOHO: true,
  MOHR: true,
  MOIL: true,
  MOIT: true,
  MOJO: true,
  MOKE: true,
  MOKI: true,
  MOKO: true,
  MOLA: true,
  MOLD: true,
  MOLE: true,
  MOLL: true,
  MOLS: true,
  MOLT: true,
  MOLY: true,
  MOME: true,
  MOMI: true,
  MOMS: true,
  MONA: true,
  MONG: true,
  MONK: true,
  MONO: true,
  MONS: true,
  MONY: true,
  MOOD: true,
  MOOI: true,
  MOOK: true,
  MOOL: true,
  MOON: true,
  MOOP: true,
  MOOR: true,
  MOOS: true,
  MOOT: true,
  MOPE: true,
  MOPS: true,
  MOPY: true,
  MORA: true,
  MORE: true,
  MORN: true,
  MORS: true,
  MORT: true,
  MOSE: true,
  MOSH: true,
  MOSK: true,
  MOSS: true,
  MOST: true,
  MOTE: true,
  MOTH: true,
  MOTI: true,
  MOTS: true,
  MOTT: true,
  MOTU: true,
  MOUE: true,
  MOUP: true,
  MOUS: true,
  MOVE: true,
  MOWA: true,
  MOWN: true,
  MOWS: true,
  MOXA: true,
  MOYA: true,
  MOYL: true,
  MOYS: true,
  MOZE: true,
  MOZO: true,
  MOZZ: true,
  MUCH: true,
  MUCK: true,
  MUDS: true,
  MUFF: true,
  MUGG: true,
  MUGS: true,
  MUID: true,
  MUIL: true,
  MUIR: true,
  MULE: true,
  MULL: true,
  MUMM: true,
  MUMP: true,
  MUMS: true,
  MUMU: true,
  MUNG: true,
  MUNI: true,
  MUNS: true,
  MUNT: true,
  MUON: true,
  MURA: true,
  MURE: true,
  MURK: true,
  MURL: true,
  MURR: true,
  MUSE: true,
  MUSH: true,
  MUSK: true,
  MUSO: true,
  MUSS: true,
  MUST: true,
  MUTE: true,
  MUTI: true,
  MUTS: true,
  MUTT: true,
  MUZZ: true,
  MWAH: true,
  MYAL: true,
  MYCS: true,
  MYNA: true,
  MYTH: true,
  MYXO: true,
  MZEE: true,
  NAAM: true,
  NAAN: true,
  NABE: true,
  NABK: true,
  NABS: true,
  NACH: true,
  NADA: true,
  NADS: true,
  NAES: true,
  NAFF: true,
  NAGA: true,
  NAGS: true,
  NAIF: true,
  NAIK: true,
  NAIL: true,
  NAIN: true,
  NALA: true,
  NAME: true,
  NAMS: true,
  NAMU: true,
  NANA: true,
  NANE: true,
  NANG: true,
  NANO: true,
  NANS: true,
  NAOI: true,
  NAOS: true,
  NAPA: true,
  NAPE: true,
  NAPS: true,
  NARC: true,
  NARD: true,
  NARE: true,
  NARK: true,
  NARY: true,
  NATS: true,
  NAVE: true,
  NAVS: true,
  NAVY: true,
  NAYS: true,
  NAZE: true,
  NAZI: true,
  NEAL: true,
  NEAP: true,
  NEAR: true,
  NEAT: true,
  NEBS: true,
  NECK: true,
  NEDS: true,
  NEED: true,
  NEEM: true,
  NEEP: true,
  NEFS: true,
  NEGS: true,
  NEIF: true,
  NEKS: true,
  NEMA: true,
  NEMN: true,
  NENE: true,
  NEON: true,
  NEPS: true,
  NERD: true,
  NERK: true,
  NESH: true,
  NESS: true,
  NEST: true,
  NETE: true,
  NETS: true,
  NETT: true,
  NEUK: true,
  NEUM: true,
  NEVE: true,
  NEVI: true,
  NEWB: true,
  NEWS: true,
  NEWT: true,
  NEXT: true,
  NGAI: true,
  NIBS: true,
  NICE: true,
  NICK: true,
  NIDE: true,
  NIDI: true,
  NIDS: true,
  NIED: true,
  NIEF: true,
  NIES: true,
  NIFE: true,
  NIFF: true,
  NIGH: true,
  NILL: true,
  NILS: true,
  NIMB: true,
  NIMS: true,
  NINE: true,
  NIPA: true,
  NIPS: true,
  NIRL: true,
  NISH: true,
  NISI: true,
  NITE: true,
  NITS: true,
  NIXE: true,
  NIXY: true,
  NOAH: true,
  NOBS: true,
  NOCK: true,
  NODE: true,
  NODI: true,
  NODS: true,
  NOEL: true,
  NOES: true,
  NOGG: true,
  NOGS: true,
  NOIL: true,
  NOIR: true,
  NOLE: true,
  NOLL: true,
  NOLO: true,
  NOMA: true,
  NOME: true,
  NOMS: true,
  NONA: true,
  NONE: true,
  NONG: true,
  NONI: true,
  NOOB: true,
  NOOK: true,
  NOON: true,
  NOOP: true,
  NOPE: true,
  NORI: true,
  NORK: true,
  NORM: true,
  NOSE: true,
  NOSH: true,
  NOSY: true,
  NOTA: true,
  NOTE: true,
  NOTT: true,
  NOUL: true,
  NOUN: true,
  NOUP: true,
  NOUS: true,
  NOUT: true,
  NOVA: true,
  NOWL: true,
  NOWN: true,
  NOWS: true,
  NOWT: true,
  NOWY: true,
  NOYS: true,
  NUBS: true,
  NUDE: true,
  NUFF: true,
  NUGS: true,
  NUKE: true,
  NULL: true,
  NUMB: true,
  NUNS: true,
  NURD: true,
  NURL: true,
  NURR: true,
  NURS: true,
  NUTS: true,
  NYAH: true,
  NYAS: true,
  NYED: true,
  NYES: true,
  OAFS: true,
  OAKS: true,
  OAKY: true,
  OARS: true,
  OARY: true,
  OAST: true,
  OATH: true,
  OATS: true,
  OATY: true,
  OBAS: true,
  OBES: true,
  OBEY: true,
  OBIA: true,
  OBIS: true,
  OBIT: true,
  OBOE: true,
  OBOL: true,
  OBOS: true,
  OBVS: true,
  OCAS: true,
  OCCY: true,
  OCHE: true,
  OCTA: true,
  ODAH: true,
  ODAL: true,
  ODAS: true,
  ODDS: true,
  ODEA: true,
  ODES: true,
  ODIC: true,
  ODOR: true,
  ODSO: true,
  ODYL: true,
  OFAY: true,
  OFFA: true,
  OFFS: true,
  OFFY: true,
  OGAM: true,
  OGEE: true,
  OGLE: true,
  OGRE: true,
  OHED: true,
  OHIA: true,
  OHMS: true,
  OIKS: true,
  OILS: true,
  OILY: true,
  OINK: true,
  OINT: true,
  OKAS: true,
  OKAY: true,
  OKEH: true,
  OKES: true,
  OKRA: true,
  OKTA: true,
  OLDE: true,
  OLDS: true,
  OLDY: true,
  OLEA: true,
  OLEO: true,
  OLES: true,
  OLID: true,
  OLIO: true,
  OLLA: true,
  OLMS: true,
  OLPE: true,
  OMAS: true,
  OMBU: true,
  OMEN: true,
  OMER: true,
  OMIT: true,
  OMOV: true,
  ONCE: true,
  ONER: true,
  ONES: true,
  ONIE: true,
  ONLY: true,
  ONOS: true,
  ONST: true,
  ONTO: true,
  ONUS: true,
  ONYX: true,
  OOFS: true,
  OOFY: true,
  OOHS: true,
  OOMS: true,
  OONS: true,
  OONT: true,
  OOPS: true,
  OOSE: true,
  OOSY: true,
  OOTS: true,
  OOZE: true,
  OOZY: true,
  OPAH: true,
  OPAL: true,
  OPAS: true,
  OPED: true,
  OPEN: true,
  OPES: true,
  OPPO: true,
  OPTS: true,
  OPUS: true,
  ORAD: true,
  ORAL: true,
  ORBS: true,
  ORBY: true,
  ORCA: true,
  ORCS: true,
  ORDO: true,
  ORDS: true,
  ORES: true,
  ORFE: true,
  ORFS: true,
  ORGS: true,
  ORGY: true,
  ORLE: true,
  ORRA: true,
  ORTS: true,
  ORYX: true,
  ORZO: true,
  OSAR: true,
  OSES: true,
  OSSA: true,
  OTIC: true,
  OTTO: true,
  OUCH: true,
  OUDS: true,
  OUKS: true,
  OULD: true,
  OULK: true,
  OUMA: true,
  OUPA: true,
  OUPH: true,
  OUPS: true,
  OURN: true,
  OURS: true,
  OUST: true,
  OUTA: true,
  OUTS: true,
  OUZO: true,
  OVAL: true,
  OVEL: true,
  OVEN: true,
  OVER: true,
  OVUM: true,
  OWED: true,
  OWER: true,
  OWES: true,
  OWLS: true,
  OWLY: true,
  OWNS: true,
  OWRE: true,
  OWSE: true,
  OWTS: true,
  OXEN: true,
  OXER: true,
  OXES: true,
  OXIC: true,
  OXID: true,
  OXIM: true,
  OYER: true,
  OYES: true,
  OYEZ: true,
  PAAL: true,
  PAAN: true,
  PACA: true,
  PACE: true,
  PACK: true,
  PACO: true,
  PACS: true,
  PACT: true,
  PACY: true,
  PADI: true,
  PADS: true,
  PAGE: true,
  PAHS: true,
  PAID: true,
  PAIK: true,
  PAIL: true,
  PAIN: true,
  PAIR: true,
  PAIS: true,
  PAKS: true,
  PALE: true,
  PALI: true,
  PALL: true,
  PALM: true,
  PALP: true,
  PALS: true,
  PALY: true,
  PAMS: true,
  PAND: true,
  PANE: true,
  PANG: true,
  PANS: true,
  PANT: true,
  PAPA: true,
  PAPE: true,
  PAPS: true,
  PARA: true,
  PARD: true,
  PARE: true,
  PARK: true,
  PARP: true,
  PARR: true,
  PARS: true,
  PART: true,
  PASE: true,
  PASH: true,
  PASS: true,
  PAST: true,
  PATE: true,
  PATH: true,
  PATS: true,
  PATU: true,
  PATY: true,
  PAUA: true,
  PAUL: true,
  PAVE: true,
  PAVS: true,
  PAWA: true,
  PAWK: true,
  PAWL: true,
  PAWN: true,
  PAWS: true,
  PAYS: true,
  PEAG: true,
  PEAK: true,
  PEAL: true,
  PEAN: true,
  PEAR: true,
  PEAS: true,
  PEAT: true,
  PEBA: true,
  PECH: true,
  PECK: true,
  PECS: true,
  PEDS: true,
  PEED: true,
  PEEK: true,
  PEEL: true,
  PEEN: true,
  PEEP: true,
  PEER: true,
  PEES: true,
  PEGH: true,
  PEGS: true,
  PEHS: true,
  PEIN: true,
  PEKE: true,
  PELA: true,
  PELE: true,
  PELF: true,
  PELL: true,
  PELS: true,
  PELT: true,
  PEND: true,
  PENE: true,
  PENI: true,
  PENK: true,
  PENS: true,
  PENT: true,
  PEON: true,
  PEPO: true,
  PEPS: true,
  PERC: true,
  PERE: true,
  PERI: true,
  PERK: true,
  PERM: true,
  PERN: true,
  PERP: true,
  PERT: true,
  PERV: true,
  PESO: true,
  PEST: true,
  PETS: true,
  PEWS: true,
  PFFT: true,
  PFUI: true,
  PHAT: true,
  PHEW: true,
  PHIS: true,
  PHIZ: true,
  PHOH: true,
  PHON: true,
  PHOS: true,
  PHOT: true,
  PHUT: true,
  PIAL: true,
  PIAN: true,
  PIAS: true,
  PICA: true,
  PICE: true,
  PICK: true,
  PICS: true,
  PIED: true,
  PIER: true,
  PIES: true,
  PIET: true,
  PIGS: true,
  PIKA: true,
  PIKE: true,
  PIKI: true,
  PILA: true,
  PILE: true,
  PILI: true,
  PILL: true,
  PILY: true,
  PIMA: true,
  PIMP: true,
  PINA: true,
  PINE: true,
  PING: true,
  PINK: true,
  PINS: true,
  PINT: true,
  PINY: true,
  PION: true,
  PIOY: true,
  PIPA: true,
  PIPE: true,
  PIPI: true,
  PIPS: true,
  PIPY: true,
  PIRL: true,
  PIRN: true,
  PIRS: true,
  PISE: true,
  PISH: true,
  PISO: true,
  PISS: true,
  PITA: true,
  PITH: true,
  PITS: true,
  PITY: true,
  PIUM: true,
  PIXY: true,
  PIZE: true,
  PLAN: true,
  PLAP: true,
  PLAT: true,
  PLAY: true,
  PLEA: true,
  PLEB: true,
  PLED: true,
  PLEW: true,
  PLEX: true,
  PLIE: true,
  PLIM: true,
  PLOD: true,
  PLOP: true,
  PLOT: true,
  PLOW: true,
  PLOY: true,
  PLUE: true,
  PLUG: true,
  PLUM: true,
  PLUS: true,
  POAS: true,
  POCK: true,
  POCO: true,
  PODS: true,
  POEM: true,
  POEP: true,
  POET: true,
  POGO: true,
  POGY: true,
  POHS: true,
  POIS: true,
  POKE: true,
  POKY: true,
  POLE: true,
  POLK: true,
  POLL: true,
  POLO: true,
  POLS: true,
  POLT: true,
  POLY: true,
  POME: true,
  POMO: true,
  POMP: true,
  POMS: true,
  POND: true,
  PONE: true,
  PONG: true,
  PONK: true,
  PONS: true,
  PONT: true,
  PONY: true,
  POOD: true,
  POOF: true,
  POOH: true,
  POOK: true,
  POOL: true,
  POON: true,
  POOP: true,
  POOR: true,
  POOS: true,
  POOT: true,
  POPE: true,
  POPS: true,
  PORE: true,
  PORK: true,
  PORN: true,
  PORT: true,
  PORY: true,
  POSE: true,
  POSH: true,
  POSS: true,
  POST: true,
  POSY: true,
  POTE: true,
  POTS: true,
  POTT: true,
  POUF: true,
  POUK: true,
  POUR: true,
  POUT: true,
  POWN: true,
  POWS: true,
  POXY: true,
  POZZ: true,
  PRAD: true,
  PRAM: true,
  PRAO: true,
  PRAT: true,
  PRAU: true,
  PRAY: true,
  PREE: true,
  PREM: true,
  PREP: true,
  PREX: true,
  PREY: true,
  PREZ: true,
  PRIG: true,
  PRIM: true,
  PROA: true,
  PROB: true,
  PROD: true,
  PROF: true,
  PROG: true,
  PROM: true,
  PROO: true,
  PROP: true,
  PROS: true,
  PROW: true,
  PRUH: true,
  PRYS: true,
  PSIS: true,
  PSST: true,
  PTUI: true,
  PUBE: true,
  PUBS: true,
  PUCE: true,
  PUCK: true,
  PUDS: true,
  PUDU: true,
  PUER: true,
  PUFF: true,
  PUGH: true,
  PUGS: true,
  PUHA: true,
  PUIR: true,
  PUJA: true,
  PUKA: true,
  PUKE: true,
  PUKU: true,
  PUKY: true,
  PULA: true,
  PULE: true,
  PULI: true,
  PULK: true,
  PULL: true,
  PULP: true,
  PULS: true,
  PULU: true,
  PULY: true,
  PUMA: true,
  PUMP: true,
  PUMY: true,
  PUNA: true,
  PUNG: true,
  PUNK: true,
  PUNS: true,
  PUNT: true,
  PUNY: true,
  PUPA: true,
  PUPS: true,
  PUPU: true,
  PURE: true,
  PURI: true,
  PURL: true,
  PURR: true,
  PURS: true,
  PUSH: true,
  PUSS: true,
  PUTS: true,
  PUTT: true,
  PUTZ: true,
  PUYS: true,
  PWNS: true,
  PYAS: true,
  PYAT: true,
  PYES: true,
  PYET: true,
  PYIC: true,
  PYIN: true,
  PYNE: true,
  PYOT: true,
  PYRE: true,
  PYRO: true,
  QADI: true,
  QAID: true,
  QATS: true,
  QINS: true,
  QOPH: true,
  QUAD: true,
  QUAG: true,
  QUAI: true,
  QUAT: true,
  QUAY: true,
  QUEP: true,
  QUEY: true,
  QUID: true,
  QUIM: true,
  QUIN: true,
  QUIP: true,
  QUIT: true,
  QUIZ: true,
  QUOD: true,
  QUOP: true,
  RABI: true,
  RACA: true,
  RACE: true,
  RACH: true,
  RACK: true,
  RACY: true,
  RADE: true,
  RADS: true,
  RAFF: true,
  RAFT: true,
  RAGA: true,
  RAGE: true,
  RAGG: true,
  RAGI: true,
  RAGS: true,
  RAGU: true,
  RAHS: true,
  RAIA: true,
  RAID: true,
  RAIK: true,
  RAIL: true,
  RAIN: true,
  RAIS: true,
  RAIT: true,
  RAJA: true,
  RAKE: true,
  RAKI: true,
  RAKU: true,
  RALE: true,
  RAMI: true,
  RAMP: true,
  RAMS: true,
  RANA: true,
  RAND: true,
  RANG: true,
  RANI: true,
  RANK: true,
  RANT: true,
  RAPE: true,
  RAPS: true,
  RAPT: true,
  RARE: true,
  RARK: true,
  RASE: true,
  RASH: true,
  RASP: true,
  RAST: true,
  RATA: true,
  RATE: true,
  RATH: true,
  RATO: true,
  RATS: true,
  RATU: true,
  RAUN: true,
  RAVE: true,
  RAVS: true,
  RAWN: true,
  RAWS: true,
  RAYA: true,
  RAYS: true,
  RAZE: true,
  RAZZ: true,
  READ: true,
  REAK: true,
  REAL: true,
  REAM: true,
  REAN: true,
  REAP: true,
  REAR: true,
  REBS: true,
  RECK: true,
  RECS: true,
  REDD: true,
  REDE: true,
  REDO: true,
  REDS: true,
  REED: true,
  REEF: true,
  REEK: true,
  REEL: true,
  REEN: true,
  REES: true,
  REFI: true,
  REFS: true,
  REFT: true,
  REGO: true,
  REGS: true,
  REHS: true,
  REIF: true,
  REIK: true,
  REIN: true,
  REIS: true,
  REKE: true,
  RELY: true,
  REMS: true,
  REND: true,
  RENK: true,
  RENO: true,
  RENS: true,
  RENT: true,
  RENY: true,
  REOS: true,
  REPO: true,
  REPP: true,
  REPS: true,
  RESH: true,
  REST: true,
  RETE: true,
  RETS: true,
  REVS: true,
  REWS: true,
  RHEA: true,
  RHOS: true,
  RHUS: true,
  RIAD: true,
  RIAL: true,
  RIAS: true,
  RIBA: true,
  RIBS: true,
  RICE: true,
  RICH: true,
  RICK: true,
  RICY: true,
  RIDE: true,
  RIDS: true,
  RIEL: true,
  RIEM: true,
  RIFE: true,
  RIFF: true,
  RIFS: true,
  RIFT: true,
  RIGG: true,
  RIGS: true,
  RILE: true,
  RILL: true,
  RIMA: true,
  RIME: true,
  RIMS: true,
  RIMU: true,
  RIMY: true,
  RIND: true,
  RINE: true,
  RING: true,
  RINK: true,
  RINS: true,
  RIOT: true,
  RIPE: true,
  RIPP: true,
  RIPS: true,
  RIPT: true,
  RISE: true,
  RISK: true,
  RISP: true,
  RITE: true,
  RITS: true,
  RITT: true,
  RITZ: true,
  RIVA: true,
  RIVE: true,
  RIVO: true,
  RIZA: true,
  ROAD: true,
  ROAM: true,
  ROAN: true,
  ROAR: true,
  ROBE: true,
  ROBS: true,
  ROCH: true,
  ROCK: true,
  ROCS: true,
  RODE: true,
  RODS: true,
  ROED: true,
  ROES: true,
  ROID: true,
  ROIL: true,
  ROIN: true,
  ROJI: true,
  ROKE: true,
  ROKS: true,
  ROKY: true,
  ROLE: true,
  ROLF: true,
  ROLL: true,
  ROMA: true,
  ROMP: true,
  ROMS: true,
  RONE: true,
  RONG: true,
  RONT: true,
  RONZ: true,
  ROOD: true,
  ROOF: true,
  ROOK: true,
  ROOM: true,
  ROON: true,
  ROOP: true,
  ROOS: true,
  ROOT: true,
  ROPE: true,
  ROPY: true,
  RORE: true,
  RORT: true,
  RORY: true,
  ROSE: true,
  ROST: true,
  ROSY: true,
  ROTA: true,
  ROTE: true,
  ROTI: true,
  ROTL: true,
  ROTO: true,
  ROTS: true,
  ROUE: true,
  ROUL: true,
  ROUM: true,
  ROUP: true,
  ROUT: true,
  ROUX: true,
  ROVE: true,
  ROWS: true,
  ROWT: true,
  RUBE: true,
  RUBS: true,
  RUBY: true,
  RUCK: true,
  RUCS: true,
  RUDD: true,
  RUDE: true,
  RUDI: true,
  RUDS: true,
  RUDY: true,
  RUED: true,
  RUER: true,
  RUES: true,
  RUFF: true,
  RUGA: true,
  RUGS: true,
  RUIN: true,
  RUKH: true,
  RULE: true,
  RULY: true,
  RUME: true,
  RUMP: true,
  RUMS: true,
  RUND: true,
  RUNE: true,
  RUNG: true,
  RUNS: true,
  RUNT: true,
  RURP: true,
  RURU: true,
  RUSA: true,
  RUSE: true,
  RUSH: true,
  RUSK: true,
  RUST: true,
  RUTH: true,
  RUTS: true,
  RYAL: true,
  RYAS: true,
  RYES: true,
  RYFE: true,
  RYKE: true,
  RYND: true,
  RYOT: true,
  RYPE: true,
  RYUS: true,
  SAAG: true,
  SABE: true,
  SABS: true,
  SACK: true,
  SACS: true,
  SADE: true,
  SADI: true,
  SADO: true,
  SADS: true,
  SAFE: true,
  SAFT: true,
  SAGA: true,
  SAGE: true,
  SAGO: true,
  SAGS: true,
  SAGY: true,
  SAIC: true,
  SAID: true,
  SAIL: true,
  SAIM: true,
  SAIN: true,
  SAIR: true,
  SAIS: true,
  SAKE: true,
  SAKI: true,
  SALE: true,
  SALL: true,
  SALP: true,
  SALS: true,
  SALT: true,
  SAMA: true,
  SAME: true,
  SAMP: true,
  SAMS: true,
  SAND: true,
  SANE: true,
  SANG: true,
  SANK: true,
  SANS: true,
  SANT: true,
  SAPS: true,
  SARD: true,
  SARI: true,
  SARK: true,
  SARS: true,
  SASH: true,
  SASS: true,
  SATE: true,
  SATI: true,
  SAUL: true,
  SAUT: true,
  SAVE: true,
  SAVS: true,
  SAWN: true,
  SAWS: true,
  SAXE: true,
  SAYS: true,
  SCAB: true,
  SCAD: true,
  SCAG: true,
  SCAM: true,
  SCAN: true,
  SCAR: true,
  SCAT: true,
  SCAW: true,
  SCOG: true,
  SCOP: true,
  SCOT: true,
  SCOW: true,
  SCRY: true,
  SCUD: true,
  SCUG: true,
  SCUL: true,
  SCUM: true,
  SCUP: true,
  SCUR: true,
  SCUT: true,
  SCYE: true,
  SEAL: true,
  SEAM: true,
  SEAN: true,
  SEAR: true,
  SEAS: true,
  SEAT: true,
  SECH: true,
  SECO: true,
  SECS: true,
  SECT: true,
  SEED: true,
  SEEK: true,
  SEEL: true,
  SEEM: true,
  SEEN: true,
  SEEP: true,
  SEER: true,
  SEES: true,
  SEGO: true,
  SEGS: true,
  SEIF: true,
  SEIK: true,
  SEIL: true,
  SEIR: true,
  SEIS: true,
  SEKT: true,
  SELD: true,
  SELE: true,
  SELF: true,
  SELL: true,
  SELS: true,
  SEME: true,
  SEMI: true,
  SENA: true,
  SEND: true,
  SENE: true,
  SENS: true,
  SENT: true,
  SEPS: true,
  SEPT: true,
  SERA: true,
  SERE: true,
  SERF: true,
  SERK: true,
  SERR: true,
  SERS: true,
  SESE: true,
  SESH: true,
  SESS: true,
  SETA: true,
  SETS: true,
  SETT: true,
  SEVS: true,
  SEWN: true,
  SEWS: true,
  SEXT: true,
  SEXY: true,
  SEYS: true,
  SHAD: true,
  SHAG: true,
  SHAH: true,
  SHAM: true,
  SHAN: true,
  SHAT: true,
  SHAW: true,
  SHAY: true,
  SHEA: true,
  SHED: true,
  SHEN: true,
  SHES: true,
  SHET: true,
  SHEW: true,
  SHHH: true,
  SHIM: true,
  SHIN: true,
  SHIP: true,
  SHIR: true,
  SHIT: true,
  SHIV: true,
  SHMO: true,
  SHOD: true,
  SHOE: true,
  SHOG: true,
  SHOO: true,
  SHOP: true,
  SHOT: true,
  SHOW: true,
  SHRI: true,
  SHUL: true,
  SHUN: true,
  SHUT: true,
  SHWA: true,
  SIAL: true,
  SIBB: true,
  SIBS: true,
  SICE: true,
  SICH: true,
  SICK: true,
  SICS: true,
  SIDA: true,
  SIDE: true,
  SIDH: true,
  SIEN: true,
  SIES: true,
  SIFT: true,
  SIGH: true,
  SIGN: true,
  SIGS: true,
  SIJO: true,
  SIKA: true,
  SIKE: true,
  SILD: true,
  SILE: true,
  SILK: true,
  SILL: true,
  SILO: true,
  SILT: true,
  SIMA: true,
  SIMI: true,
  SIMP: true,
  SIMS: true,
  SIND: true,
  SINE: true,
  SING: true,
  SINH: true,
  SINK: true,
  SINS: true,
  SIPE: true,
  SIPS: true,
  SIRE: true,
  SIRI: true,
  SIRS: true,
  SISS: true,
  SIST: true,
  SITE: true,
  SITH: true,
  SITS: true,
  SITZ: true,
  SIZE: true,
  SIZY: true,
  SJOE: true,
  SKAG: true,
  SKAS: true,
  SKAT: true,
  SKAW: true,
  SKED: true,
  SKEE: true,
  SKEG: true,
  SKEN: true,
  SKEO: true,
  SKEP: true,
  SKER: true,
  SKET: true,
  SKEW: true,
  SKID: true,
  SKIM: true,
  SKIN: true,
  SKIO: true,
  SKIP: true,
  SKIS: true,
  SKIT: true,
  SKOG: true,
  SKOL: true,
  SKRY: true,
  SKUA: true,
  SKUG: true,
  SKYF: true,
  SKYR: true,
  SLAB: true,
  SLAE: true,
  SLAG: true,
  SLAM: true,
  SLAP: true,
  SLAT: true,
  SLAW: true,
  SLAY: true,
  SLEB: true,
  SLED: true,
  SLEE: true,
  SLEW: true,
  SLEY: true,
  SLID: true,
  SLIM: true,
  SLIP: true,
  SLIT: true,
  SLOB: true,
  SLOE: true,
  SLOG: true,
  SLOP: true,
  SLOT: true,
  SLOW: true,
  SLUB: true,
  SLUE: true,
  SLUG: true,
  SLUM: true,
  SLUR: true,
  SLUT: true,
  SMEE: true,
  SMEW: true,
  SMIR: true,
  SMIT: true,
  SMOG: true,
  SMUG: true,
  SMUR: true,
  SMUT: true,
  SNAB: true,
  SNAG: true,
  SNAP: true,
  SNAR: true,
  SNAW: true,
  SNEB: true,
  SNED: true,
  SNEE: true,
  SNIB: true,
  SNIG: true,
  SNIP: true,
  SNIT: true,
  SNOB: true,
  SNOD: true,
  SNOG: true,
  SNOT: true,
  SNOW: true,
  SNUB: true,
  SNUG: true,
  SNYE: true,
  SOAK: true,
  SOAP: true,
  SOAR: true,
  SOBA: true,
  SOBS: true,
  SOCA: true,
  SOCK: true,
  SOCS: true,
  SODA: true,
  SODS: true,
  SOFA: true,
  SOFT: true,
  SOGS: true,
  SOHO: true,
  SOHS: true,
  SOIL: true,
  SOJA: true,
  SOJU: true,
  SOKE: true,
  SOLA: true,
  SOLD: true,
  SOLE: true,
  SOLI: true,
  SOLO: true,
  SOLS: true,
  SOMA: true,
  SOME: true,
  SOMS: true,
  SOMY: true,
  SONE: true,
  SONG: true,
  SONS: true,
  SOOK: true,
  SOOL: true,
  SOOM: true,
  SOON: true,
  SOOP: true,
  SOOT: true,
  SOPH: true,
  SOPS: true,
  SORA: true,
  SORB: true,
  SORD: true,
  SORE: true,
  SORI: true,
  SORN: true,
  SORT: true,
  SOSS: true,
  SOTH: true,
  SOTS: true,
  SOUK: true,
  SOUL: true,
  SOUM: true,
  SOUP: true,
  SOUR: true,
  SOUS: true,
  SOUT: true,
  SOVS: true,
  SOWF: true,
  SOWL: true,
  SOWM: true,
  SOWN: true,
  SOWP: true,
  SOWS: true,
  SOYA: true,
  SOYS: true,
  SPAE: true,
  SPAG: true,
  SPAM: true,
  SPAN: true,
  SPAR: true,
  SPAS: true,
  SPAT: true,
  SPAW: true,
  SPAY: true,
  SPAZ: true,
  SPEC: true,
  SPED: true,
  SPEK: true,
  SPET: true,
  SPEW: true,
  SPIC: true,
  SPIE: true,
  SPIF: true,
  SPIK: true,
  SPIM: true,
  SPIN: true,
  SPIT: true,
  SPIV: true,
  SPOD: true,
  SPOT: true,
  SPRY: true,
  SPUD: true,
  SPUE: true,
  SPUG: true,
  SPUN: true,
  SPUR: true,
  SRIS: true,
  STAB: true,
  STAG: true,
  STAP: true,
  STAR: true,
  STAT: true,
  STAW: true,
  STAY: true,
  STED: true,
  STEM: true,
  STEN: true,
  STEP: true,
  STET: true,
  STEW: true,
  STEY: true,
  STIE: true,
  STIM: true,
  STIR: true,
  STOA: true,
  STOB: true,
  STOP: true,
  STOT: true,
  STOW: true,
  STUB: true,
  STUD: true,
  STUM: true,
  STUN: true,
  STYE: true,
  SUBA: true,
  SUBS: true,
  SUCH: true,
  SUCK: true,
  SUDD: true,
  SUDS: true,
  SUED: true,
  SUER: true,
  SUES: true,
  SUET: true,
  SUGH: true,
  SUGO: true,
  SUGS: true,
  SUID: true,
  SUIT: true,
  SUKH: true,
  SUKS: true,
  SULK: true,
  SULU: true,
  SUMI: true,
  SUMO: true,
  SUMP: true,
  SUMS: true,
  SUMY: true,
  SUNG: true,
  SUNI: true,
  SUNK: true,
  SUNN: true,
  SUNS: true,
  SUPE: true,
  SUPS: true,
  SUQS: true,
  SURA: true,
  SURD: true,
  SURE: true,
  SURF: true,
  SUSS: true,
  SUSU: true,
  SWAB: true,
  SWAD: true,
  SWAG: true,
  SWAM: true,
  SWAN: true,
  SWAP: true,
  SWAT: true,
  SWAY: true,
  SWEE: true,
  SWEY: true,
  SWIG: true,
  SWIM: true,
  SWIZ: true,
  SWOB: true,
  SWOP: true,
  SWOT: true,
  SWUM: true,
  SYBO: true,
  SYCE: true,
  SYED: true,
  SYEN: true,
  SYES: true,
  SYKE: true,
  SYLI: true,
  SYNC: true,
  SYND: true,
  SYNE: true,
  SYPE: true,
  SYPH: true,
  TAAL: true,
  TABI: true,
  TABS: true,
  TABU: true,
  TACE: true,
  TACH: true,
  TACK: true,
  TACO: true,
  TACT: true,
  TADS: true,
  TAED: true,
  TAEL: true,
  TAES: true,
  TAGS: true,
  TAHA: true,
  TAHR: true,
  TAIG: true,
  TAIL: true,
  TAIN: true,
  TAIS: true,
  TAIT: true,
  TAKA: true,
  TAKE: true,
  TAKI: true,
  TAKS: true,
  TAKY: true,
  TALA: true,
  TALC: true,
  TALE: true,
  TALI: true,
  TALK: true,
  TALL: true,
  TAME: true,
  TAMP: true,
  TAMS: true,
  TANA: true,
  TANE: true,
  TANG: true,
  TANH: true,
  TANK: true,
  TANS: true,
  TAOS: true,
  TAPA: true,
  TAPE: true,
  TAPS: true,
  TAPU: true,
  TARA: true,
  TARE: true,
  TARN: true,
  TARO: true,
  TARP: true,
  TARS: true,
  TART: true,
  TASE: true,
  TASH: true,
  TASK: true,
  TASS: true,
  TATE: true,
  TATH: true,
  TATS: true,
  TATT: true,
  TATU: true,
  TAUS: true,
  TAUT: true,
  TAVA: true,
  TAVS: true,
  TAWA: true,
  TAWS: true,
  TAWT: true,
  TAXA: true,
  TAXI: true,
  TAYS: true,
  TEAD: true,
  TEAK: true,
  TEAL: true,
  TEAM: true,
  TEAR: true,
  TEAS: true,
  TEAT: true,
  TECH: true,
  TECS: true,
  TEDS: true,
  TEDY: true,
  TEED: true,
  TEEK: true,
  TEEL: true,
  TEEM: true,
  TEEN: true,
  TEER: true,
  TEES: true,
  TEFF: true,
  TEFS: true,
  TEGG: true,
  TEGS: true,
  TEGU: true,
  TEHR: true,
  TEIL: true,
  TEIN: true,
  TELA: true,
  TELD: true,
  TELE: true,
  TELL: true,
  TELS: true,
  TELT: true,
  TEME: true,
  TEMP: true,
  TEMS: true,
  TEND: true,
  TENE: true,
  TENS: true,
  TENT: true,
  TEPA: true,
  TERF: true,
  TERM: true,
  TERN: true,
  TEST: true,
  TETE: true,
  TETH: true,
  TETS: true,
  TEWS: true,
  TEXT: true,
  THAE: true,
  THAN: true,
  THAR: true,
  THAT: true,
  THAW: true,
  THEE: true,
  THEM: true,
  THEN: true,
  THEW: true,
  THEY: true,
  THIG: true,
  THIN: true,
  THIO: true,
  THIR: true,
  THIS: true,
  THON: true,
  THOU: true,
  THRO: true,
  THRU: true,
  THUD: true,
  THUG: true,
  THUS: true,
  TIAN: true,
  TIAR: true,
  TICE: true,
  TICH: true,
  TICK: true,
  TICS: true,
  TIDE: true,
  TIDS: true,
  TIDY: true,
  TIED: true,
  TIER: true,
  TIES: true,
  TIFF: true,
  TIFT: true,
  TIGE: true,
  TIGS: true,
  TIKA: true,
  TIKE: true,
  TIKI: true,
  TIKS: true,
  TILE: true,
  TILL: true,
  TILS: true,
  TILT: true,
  TIME: true,
  TINA: true,
  TIND: true,
  TINE: true,
  TING: true,
  TINK: true,
  TINS: true,
  TINT: true,
  TINY: true,
  TIPI: true,
  TIPS: true,
  TIPT: true,
  TIRE: true,
  TIRL: true,
  TIRO: true,
  TIRR: true,
  TITE: true,
  TITI: true,
  TITS: true,
  TIVY: true,
  TIYN: true,
  TIZZ: true,
  TOAD: true,
  TOBY: true,
  TOCK: true,
  TOCO: true,
  TOCS: true,
  TODS: true,
  TODY: true,
  TOEA: true,
  TOED: true,
  TOES: true,
  TOEY: true,
  TOFF: true,
  TOFT: true,
  TOFU: true,
  TOGA: true,
  TOGE: true,
  TOGS: true,
  TOHO: true,
  TOIL: true,
  TOIT: true,
  TOKE: true,
  TOKO: true,
  TOLA: true,
  TOLD: true,
  TOLE: true,
  TOLL: true,
  TOLT: true,
  TOLU: true,
  TOMB: true,
  TOME: true,
  TOMO: true,
  TOMS: true,
  TONE: true,
  TONG: true,
  TONK: true,
  TONS: true,
  TONY: true,
  TOOK: true,
  TOOL: true,
  TOOM: true,
  TOON: true,
  TOOT: true,
  TOPE: true,
  TOPH: true,
  TOPI: true,
  TOPO: true,
  TOPS: true,
  TORA: true,
  TORC: true,
  TORE: true,
  TORI: true,
  TORN: true,
  TORO: true,
  TORR: true,
  TORS: true,
  TORT: true,
  TORY: true,
  TOSA: true,
  TOSE: true,
  TOSH: true,
  TOSS: true,
  TOST: true,
  TOTE: true,
  TOTS: true,
  TOUK: true,
  TOUN: true,
  TOUR: true,
  TOUT: true,
  TOWN: true,
  TOWS: true,
  TOWT: true,
  TOWY: true,
  TOYO: true,
  TOYS: true,
  TOZE: true,
  TRAD: true,
  TRAM: true,
  TRAP: true,
  TRAT: true,
  TRAY: true,
  TREE: true,
  TREF: true,
  TREK: true,
  TREM: true,
  TRES: true,
  TRET: true,
  TREW: true,
  TREY: true,
  TREZ: true,
  TRIE: true,
  TRIG: true,
  TRIM: true,
  TRIN: true,
  TRIO: true,
  TRIP: true,
  TROD: true,
  TROG: true,
  TRON: true,
  TROP: true,
  TROT: true,
  TROU: true,
  TROW: true,
  TROY: true,
  TRUE: true,
  TRUG: true,
  TRYE: true,
  TRYP: true,
  TSAR: true,
  TSKS: true,
  TUAN: true,
  TUBA: true,
  TUBE: true,
  TUBS: true,
  TUCK: true,
  TUFA: true,
  TUFF: true,
  TUFT: true,
  TUGS: true,
  TUIS: true,
  TULE: true,
  TUMP: true,
  TUMS: true,
  TUNA: true,
  TUND: true,
  TUNE: true,
  TUNG: true,
  TUNS: true,
  TUNY: true,
  TUPS: true,
  TURD: true,
  TURF: true,
  TURK: true,
  TURM: true,
  TURN: true,
  TURR: true,
  TUSH: true,
  TUSK: true,
  TUTS: true,
  TUTU: true,
  TUZZ: true,
  TWAE: true,
  TWAL: true,
  TWAS: true,
  TWAT: true,
  TWAY: true,
  TWEE: true,
  TWIG: true,
  TWIN: true,
  TWIT: true,
  TWOS: true,
  TYDE: true,
  TYED: true,
  TYEE: true,
  TYER: true,
  TYES: true,
  TYGS: true,
  TYIN: true,
  TYKE: true,
  TYMP: true,
  TYND: true,
  TYNE: true,
  TYPE: true,
  TYPO: true,
  TYPP: true,
  TYPY: true,
  TYRE: true,
  TYRO: true,
  TYTE: true,
  TZAR: true,
  UDAL: true,
  UDON: true,
  UDOS: true,
  UEYS: true,
  UFOS: true,
  UGHS: true,
  UGLY: true,
  UKES: true,
  ULAN: true,
  ULES: true,
  ULEX: true,
  ULNA: true,
  ULUS: true,
  ULVA: true,
  UMBO: true,
  UMMA: true,
  UMPH: true,
  UMPS: true,
  UMPY: true,
  UMRA: true,
  UMUS: true,
  UNAI: true,
  UNAU: true,
  UNBE: true,
  UNCE: true,
  UNCI: true,
  UNCO: true,
  UNDE: true,
  UNDO: true,
  UNDY: true,
  UNIS: true,
  UNIT: true,
  UNTO: true,
  UPAS: true,
  UPBY: true,
  UPDO: true,
  UPGO: true,
  UPON: true,
  UPSY: true,
  UPTA: true,
  URAO: true,
  URBS: true,
  URDE: true,
  URDS: true,
  URDY: true,
  UREA: true,
  URES: true,
  URGE: true,
  URIC: true,
  URNS: true,
  URPS: true,
  URSA: true,
  URUS: true,
  URVA: true,
  USED: true,
  USER: true,
  USES: true,
  UTAS: true,
  UTES: true,
  UTIS: true,
  UTUS: true,
  UVAE: true,
  UVAS: true,
  UVEA: true,
  VACS: true,
  VADE: true,
  VAES: true,
  VAGI: true,
  VAGS: true,
  VAIL: true,
  VAIN: true,
  VAIR: true,
  VALE: true,
  VALI: true,
  VAMP: true,
  VANE: true,
  VANG: true,
  VANS: true,
  VANT: true,
  VAPE: true,
  VARA: true,
  VARE: true,
  VARS: true,
  VARY: true,
  VASA: true,
  VASE: true,
  VAST: true,
  VATS: true,
  VATU: true,
  VAUS: true,
  VAUT: true,
  VAVS: true,
  VAWS: true,
  VEAL: true,
  VEEP: true,
  VEER: true,
  VEES: true,
  VEGA: true,
  VEGO: true,
  VEHM: true,
  VEIL: true,
  VEIN: true,
  VELA: true,
  VELD: true,
  VELE: true,
  VELL: true,
  VENA: true,
  VEND: true,
  VENT: true,
  VERA: true,
  VERB: true,
  VERD: true,
  VERS: true,
  VERT: true,
  VERY: true,
  VEST: true,
  VETO: true,
  VETS: true,
  VEXT: true,
  VIAE: true,
  VIAL: true,
  VIAS: true,
  VIBE: true,
  VIBS: true,
  VICE: true,
  VIDE: true,
  VIDS: true,
  VIED: true,
  VIER: true,
  VIES: true,
  VIEW: true,
  VIFF: true,
  VIGA: true,
  VIGS: true,
  VILD: true,
  VILE: true,
  VILL: true,
  VIMS: true,
  VINA: true,
  VINE: true,
  VINO: true,
  VINS: true,
  VINT: true,
  VINY: true,
  VIOL: true,
  VIRE: true,
  VIRL: true,
  VISA: true,
  VISE: true,
  VITA: true,
  VITE: true,
  VIVA: true,
  VIVE: true,
  VIVO: true,
  VIZY: true,
  VLEI: true,
  VLOG: true,
  VOAR: true,
  VOES: true,
  VOGS: true,
  VOID: true,
  VOIP: true,
  VOLA: true,
  VOLE: true,
  VOLK: true,
  VOLS: true,
  VOLT: true,
  VOMS: true,
  VORS: true,
  VOTE: true,
  VOWS: true,
  VRIL: true,
  VROT: true,
  VROU: true,
  VROW: true,
  VUGG: true,
  VUGH: true,
  VUGS: true,
  VULN: true,
  VUMS: true,
  WAAC: true,
  WAAH: true,
  WABS: true,
  WACK: true,
  WADD: true,
  WADE: true,
  WADI: true,
  WADS: true,
  WADT: true,
  WADY: true,
  WAES: true,
  WAFF: true,
  WAFT: true,
  WAGE: true,
  WAGS: true,
  WAID: true,
  WAIF: true,
  WAIL: true,
  WAIN: true,
  WAIR: true,
  WAIS: true,
  WAIT: true,
  WAKA: true,
  WAKE: true,
  WAKF: true,
  WALD: true,
  WALE: true,
  WALI: true,
  WALK: true,
  WALL: true,
  WALY: true,
  WAME: true,
  WAND: true,
  WANE: true,
  WANG: true,
  WANK: true,
  WANS: true,
  WANT: true,
  WANY: true,
  WAPS: true,
  WAQF: true,
  WARB: true,
  WARD: true,
  WARE: true,
  WARK: true,
  WARM: true,
  WARN: true,
  WARP: true,
  WARS: true,
  WART: true,
  WARY: true,
  WASE: true,
  WASH: true,
  WASM: true,
  WASP: true,
  WAST: true,
  WATE: true,
  WATS: true,
  WATT: true,
  WAUK: true,
  WAUL: true,
  WAUR: true,
  WAVE: true,
  WAVY: true,
  WAWA: true,
  WAWE: true,
  WAWL: true,
  WAWS: true,
  WAXY: true,
  WAYS: true,
  WAZZ: true,
  WEAK: true,
  WEAL: true,
  WEAN: true,
  WEAR: true,
  WEBS: true,
  WEDS: true,
  WEED: true,
  WEEK: true,
  WEEL: true,
  WEEM: true,
  WEEN: true,
  WEEP: true,
  WEER: true,
  WEES: true,
  WEET: true,
  WEFT: true,
  WEID: true,
  WEIL: true,
  WEIR: true,
  WEKA: true,
  WELD: true,
  WELK: true,
  WELL: true,
  WELS: true,
  WELT: true,
  WEMB: true,
  WEMS: true,
  WENA: true,
  WEND: true,
  WENS: true,
  WENT: true,
  WEPT: true,
  WERE: true,
  WERO: true,
  WERT: true,
  WEST: true,
  WETA: true,
  WETS: true,
  WEXE: true,
  WEYS: true,
  WHAE: true,
  WHAM: true,
  WHAP: true,
  WHAT: true,
  WHEE: true,
  WHEN: true,
  WHET: true,
  WHEW: true,
  WHEY: true,
  WHID: true,
  WHIG: true,
  WHIM: true,
  WHIN: true,
  WHIO: true,
  WHIP: true,
  WHIR: true,
  WHIT: true,
  WHIZ: true,
  WHOA: true,
  WHOM: true,
  WHOP: true,
  WHOT: true,
  WHOW: true,
  WHUP: true,
  WHYS: true,
  WICE: true,
  WICH: true,
  WICK: true,
  WIDE: true,
  WIEL: true,
  WIFE: true,
  WIGS: true,
  WIKI: true,
  WILD: true,
  WILE: true,
  WILI: true,
  WILL: true,
  WILT: true,
  WILY: true,
  WIMP: true,
  WIND: true,
  WINE: true,
  WING: true,
  WINK: true,
  WINN: true,
  WINO: true,
  WINS: true,
  WINY: true,
  WIPE: true,
  WIRE: true,
  WIRY: true,
  WISE: true,
  WISH: true,
  WISP: true,
  WISS: true,
  WIST: true,
  WITE: true,
  WITH: true,
  WITS: true,
  WIVE: true,
  WOAD: true,
  WOAH: true,
  WOCK: true,
  WOES: true,
  WOFS: true,
  WOGS: true,
  WOKE: true,
  WOKS: true,
  WOLD: true,
  WOLF: true,
  WOMB: true,
  WONK: true,
  WONS: true,
  WONT: true,
  WOOD: true,
  WOOF: true,
  WOOL: true,
  WOON: true,
  WOOS: true,
  WOOT: true,
  WOPS: true,
  WORD: true,
  WORE: true,
  WORK: true,
  WORM: true,
  WORN: true,
  WORT: true,
  WOST: true,
  WOTS: true,
  WOVE: true,
  WOWF: true,
  WOWS: true,
  WRAP: true,
  WREN: true,
  WRIT: true,
  WUDS: true,
  WUDU: true,
  WULL: true,
  WUSS: true,
  WYCH: true,
  WYES: true,
  WYLE: true,
  WYND: true,
  WYNN: true,
  WYNS: true,
  WYTE: true,
  XRAY: true,
  XYST: true,
  YAAR: true,
  YABA: true,
  YACK: true,
  YADS: true,
  YAFF: true,
  YAGE: true,
  YAGI: true,
  YAGS: true,
  YAHS: true,
  YAKS: true,
  YALD: true,
  YALE: true,
  YAMS: true,
  YANG: true,
  YANK: true,
  YAPP: true,
  YAPS: true,
  YARD: true,
  YARE: true,
  YARK: true,
  YARN: true,
  YARR: true,
  YATE: true,
  YAUD: true,
  YAUP: true,
  YAWL: true,
  YAWN: true,
  YAWP: true,
  YAWS: true,
  YAWY: true,
  YAYS: true,
  YBET: true,
  YEAD: true,
  YEAH: true,
  YEAN: true,
  YEAR: true,
  YEAS: true,
  YEBO: true,
  YECH: true,
  YEDE: true,
  YEED: true,
  YEGG: true,
  YELD: true,
  YELK: true,
  YELL: true,
  YELM: true,
  YELP: true,
  YELT: true,
  YENS: true,
  YEOW: true,
  YEPS: true,
  YERD: true,
  YERK: true,
  YESK: true,
  YEST: true,
  YETI: true,
  YETT: true,
  YEUK: true,
  YEVE: true,
  YEWS: true,
  YGOE: true,
  YIDS: true,
  YIKE: true,
  YILL: true,
  YINS: true,
  YIPE: true,
  YIPS: true,
  YIRD: true,
  YIRK: true,
  YIRR: true,
  YITE: true,
  YLEM: true,
  YLKE: true,
  YMPE: true,
  YMPT: true,
  YOBS: true,
  YOCK: true,
  YODE: true,
  YODH: true,
  YODS: true,
  YOGA: true,
  YOGH: true,
  YOGI: true,
  YOKE: true,
  YOKS: true,
  YOLD: true,
  YOLK: true,
  YOMP: true,
  YOND: true,
  YONI: true,
  YONT: true,
  YOOF: true,
  YOOP: true,
  YORE: true,
  YORK: true,
  YORP: true,
  YOUK: true,
  YOUR: true,
  YOUS: true,
  YOWE: true,
  YOWL: true,
  YOWS: true,
  YUAN: true,
  YUCA: true,
  YUCH: true,
  YUCK: true,
  YUFT: true,
  YUGA: true,
  YUGS: true,
  YUKE: true,
  YUKO: true,
  YUKS: true,
  YUKY: true,
  YULE: true,
  YUMP: true,
  YUNX: true,
  YUPS: true,
  YURT: true,
  YUTZ: true,
  YUZU: true,
  YWIS: true,
  ZACK: true,
  ZAGS: true,
  ZANY: true,
  ZAPS: true,
  ZARF: true,
  ZARI: true,
  ZATI: true,
  ZEAL: true,
  ZEAS: true,
  ZEBU: true,
  ZEDA: true,
  ZEDS: true,
  ZEES: true,
  ZEIN: true,
  ZEKS: true,
  ZELS: true,
  ZEPS: true,
  ZERK: true,
  ZERO: true,
  ZEST: true,
  ZETA: true,
  ZEZE: true,
  ZHOS: true,
  ZIFF: true,
  ZIGS: true,
  ZILA: true,
  ZILL: true,
  ZIMB: true,
  ZINC: true,
  ZINE: true,
  ZING: true,
  ZINS: true,
  ZIPS: true,
  ZITE: true,
  ZITI: true,
  ZITS: true,
  ZIZZ: true,
  ZOBO: true,
  ZOBU: true,
  ZOEA: true,
  ZOIC: true,
  ZOLS: true,
  ZONA: true,
  ZONE: true,
  ZONK: true,
  ZOOM: true,
  ZOON: true,
  ZOOS: true,
  ZOOT: true,
  ZORI: true,
  ZOUK: true,
  ZULU: true,
  ZUPA: true,
  ZURF: true,
  ZYGA: true,
  ZYME: true,
  ZZZS: true
}

export default words;
