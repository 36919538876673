import "../../../styles/components/score.css";
import { RxStar } from "react-icons/rx";

type ScoreProps = {
  score: number;
}

function Score({score}: ScoreProps) {
  return (
    <div className="Score">
      <div className="score-container">
        <span className="metric-icon"><RxStar /></span>
        <span className="score-count">{score}</span>
      </div>
    </div>
  );
}

export default Score;
