import "../../../styles/components/timer.css";
import { useState, useEffect } from "react";
import { RxStopwatch } from "react-icons/rx";

type TimerProps = {
  startTimer: boolean;
  seconds: number;
  onEnd: () => void;
}

function Timer({seconds, startTimer, onEnd}: TimerProps) {
  const [currentSecond, setCurrentSeconds] = useState<number>(seconds);

  useEffect(function() {
    let id: ReturnType<typeof setInterval>;

    if (startTimer) {
      id = setInterval(function() {
        setCurrentSeconds(prev => {
          if (prev > 0) {
            return prev - 1
          }
          return 0;
        });
      }, 1000);
    }

    return () => {
      clearInterval(id);
    }
  }, [startTimer]);

  useEffect(function() {
    if (currentSecond === 0) {
      onEnd();
    }
  }, [currentSecond, onEnd]);

  return (
    <div className="Timer">
      <div className="timer-container">
        <span className="metric-icon"><RxStopwatch /></span>
        <span className="timer-contents">
          {`${currentSecond}`.padStart(2, '0')}
          <span className="seconds">s</span>
        </span>
      </div>
    </div>
  );
}

export default Timer;
