import "../../../../styles/components/modal.css";
import { RxCross1 } from "react-icons/rx";

type ModalProps = {
  children: React.ReactNode;
  onClose: () => void;
}

function Modal({children, onClose}: ModalProps) {
  return (
    <div data-testid="modal-mask" className="Modal" onClick={onClose}>
      <div data-testid="modal-dialog" className="modal-dialog" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <span data-testid="modal-close" className="close-button" onClick={onClose}>
            <RxCross1 />
          </span>
        </div>
        <div className="modal-body">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
