import "../../../styles/components/rules.css";
import Letter from "../word/Letter";
import Modal from "../common/modal";
import Button from "../common/button";
import Key from "../keyboard/Key";
import Game from "../../game/Game";

type ExampleWordProps = {
  word: string;
  letter?: string;
  highlightIndex?: number;
}

function ExampleWord({word, letter, highlightIndex}: ExampleWordProps) {
  return (
    <div className="example-word">
      <Letter value={word[0]} letterIndex={0} disabled={true} highlight={highlightIndex === 0} />
      <Letter value={word[1]} letterIndex={1} disabled={true} highlight={highlightIndex === 1} />
      <Letter value={word[2]} letterIndex={2} disabled={true} highlight={highlightIndex === 2} />
      <Letter value={word[3]} letterIndex={3} disabled={true} highlight={highlightIndex === 3} />
      
      {letter &&
        <div className="example-word-key">
          <span>+</span>
          <Key letter={letter} onClick={() => {}} isPlaying={false} />
        </div>
      }
    </div>
  );
}

type RulesProps = {
  startGame: () => void;
  onClose: () => void;
}

function Rules({startGame, onClose}: RulesProps) {
  return (
    <Modal onClose={onClose}>
      <div>
        <div className="section">
          <span className="modal-title">How To Play</span>
          <span className="modal-sub-title">How many words can you make in 60 seconds?</span>
          <ul className="rule-list">
            <li>Select a letter from the word box to change</li>
            <li>Click a key to replace it with to make a new word</li>
            <li>Come back again tomorrow to try your luck at a new starting word</li>
          </ul>
        </div>

        <div className="section">
          <span className="modal-sub-title">Examples</span>
          <div className="examples">
            {ExampleWord({word: "RACE", letter: 'P', highlightIndex: 0})}
            {ExampleWord({word: "PACE", letter: 'T', highlightIndex: 3})}
            {ExampleWord({word: "PACT"})}
          </div>
        </div>
      </div>

      <div className="section start-game">
        <Button handleClick={startGame}>Start</Button>
      </div>

      <div className="section footer">
        <span>Contact the developer at <a href={`mailto:${Game.email}`}>{Game.email}</a></span>
      </div>
    </Modal>
  );
}

export default Rules;
